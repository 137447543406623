import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import EditGradeTableItem from "./EditGradeTableItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders } from "../../functions/apiService";
import SucessModal from "../SucessModal";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup, setSelectedGroupSubject } from "../../store/action";

function EditGradeTable({ role }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const { groupId: paramGroupId } = useParams();
	const [groupID, setGroupID] = useState(paramGroupId || null);
	const { getData } = location.state || {};
	// const { selectedGroup } = location.state || {};
	const { groups } = location.state || {};
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [openSelectSubject, setOpenSelectSubject] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [showCalendar, setShowCalendar] = useState(false);
	const calendarRef = useRef(null);
	const [updatedStudentsData, setUpdatedStudentsData] = useState([]);

	const [selectedSubject, setSelectedSubject] = useState("daily");

	const [errors, setErrors] = useState({});

	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setShowCalendar(false);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		if (showCalendar) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showCalendar]);

	const groupName =
		groups.find((group) => group.id === parseInt(selectedGroup))?.name ||
		"Qrup tapılmadı";

	const handleSubjectChange = (event) => {
		setSelectedSubject(event.target.value);
		setOpenSelectSubject(false);
	};

	const validateForm = () => {
		let formErrors = {};

		const studentsWithNoGradeId = updatedStudentsData.filter(
			(student) => student.grade_id === null
		);

		const missingGrades = studentsWithNoGradeId.map(student => {
			const foundStudent = allData.students.find(s => s.id === student.student_id);
			return foundStudent ? foundStudent.full_name : null;
		}).filter(name => name !== null);

		const studentsWithGradeOrAttendance = studentsWithNoGradeId.filter(student => student.grade !== null || student.attendance !== null);

		if (studentsWithGradeOrAttendance.length > 0) {
			formErrors.noGroupStudent = `${missingGrades.join(", ")} qrupa sonradan əlavə edilib.`;
		}

		const allStudentsHaveNoGradeId = updatedStudentsData.length > 0 && studentsWithNoGradeId.length === updatedStudentsData.length;

		if (allStudentsHaveNoGradeId) {
			formErrors.noGradeId = "Seçdiyiniz günə forma doldurulmamışdır.";
		}

		setErrors(formErrors);

		return Object.keys(formErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const studentsWithGrades = allData.students.filter(
			(student) => student.grades && student.grades.length > 0
		);

		const formattedDate = selectedDate.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});


		const putRequests = updatedStudentsData.map((student) => {
			const gradeId = student.grade_id;
			if (!gradeId) return null;

			const requestData = {
				attendance: +student.attendance,
				grade: student.grade !== null ? +student.grade : null,
				note: student.note || null,
				date: formattedDate,
				status: selectedSubject,
			};

			return fetch(`${consts.API_URL}/teacher/grade/${gradeId}`, {
				method: "PUT",
				headers: createHeaders(),
				body: JSON.stringify(requestData),
			});
		}).filter(Boolean);

		try {
			const responses = await Promise.allSettled(putRequests);
			const errors = responses
				.filter((response) => response.status === "rejected" || !response.value.ok)
				.map((response) => response.reason || response.value?.statusText || "Ошибка сети");
			setShowSuccessModal(true);
		} catch (error) {
			handleUnauthorizedError(error);
		}
	};

	const [allData, setAllData] = useState([])

	useEffect(() => {
		if (selectedGroup && selectedSubject && selectedGroupSubject) {
			const formattedDate = selectedDate.toLocaleDateString("ru-RU", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			});

			const headers = createHeaders();

			const baseUrl = `${consts.API_URL}/teacher/group/get/student`;

			const queryParams = new URLSearchParams({
				// pagination: perPage,
				// page: currentPage,
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: formattedDate,
				date_to: formattedDate,
				status: selectedSubject,
			});

			// if (sortBy) {
			// 	queryParams.append(sortBy, sortDirection);
			// }

			fetch(`${baseUrl}?${queryParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setAllData(data.data);
					// setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});

			const allStudentsParams = new URLSearchParams({
				// pagination: 1000,
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: formattedDate,
				date_to: formattedDate,
				status: selectedSubject,
			});

			fetch(`${baseUrl}?${allStudentsParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setAllData(data.data);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});
		}
	}, [
		selectedGroup,
		selectedGroupSubject,
		selectedSubject,
		selectedDate,
		// perPage,
		// currentPage,
	]);

	const handleUpdateStudent = (updatedStudent) => {
		setUpdatedStudentsData((prevData) => {
			const gradeId = allData.students?.find(
				(student) => student.id === updatedStudent.student_id
			)?.grades?.[0]?.id || null;

			const updatedData = prevData.map((student) =>
				student.student_id === updatedStudent.student_id
					? {
						...student,
						...updatedStudent,
						grade: updatedStudent.grade !== null ? Number(updatedStudent.grade) : null,
						grade_id: gradeId,
					}
					: student
			);

			const studentExists = prevData.some(
				(student) => student.student_id === updatedStudent.student_id
			);

			if (!studentExists) {
				updatedData.push({
					...updatedStudent,
					grade: updatedStudent.grade !== null ? Number(updatedStudent.grade) : null,
					grade_id: gradeId,
				});
			}

			return updatedData;
		});
	};

	const handleGoBack = () => {
		if (window.innerWidth <= 768) {
			navigate("/teacher");
		} else {
			navigate("/grade");
		}
	};

	return (
		<>
			<SucessModal
				show={showSuccessModal}
				handleClose={() => setShowSuccessModal(false)}
				role={role}
				groupID={groupID}
			/>
			<form action="">
				<div className="groups">
					<div className="groups-header groups-header-grade">
						<div className="header-left">
							<h3 className="title mb-3">{groupName}</h3>
						</div>
						<div className="header-right">
							<p
								className="day-navigation"
								style={{ position: "relative" }}
								onClick={() => setShowCalendar((prev) => !prev)}
							>
								<span>{selectedDate.toLocaleDateString()}</span>
								<svg
									width="17"
									height="18"
									viewBox="0 0 17 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.8543 0.583374V3.75004M4.146 0.583374V3.75004"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M15.625 2.16675H1.375V16.4167H15.625V2.16675Z"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M7.7168 9.6875H11.6751M5.3418 9.6875H5.34891M9.30013 12.8542H5.3418M11.6751 12.8542H11.668"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinecap="square"
										strokeLinejoin="round"
									/>
									<path
										d="M1.375 6.125H15.625"
										stroke="#707070"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</p>
							<div className="group-subject d-flex align-items-center">
								<Form.Select
									className={`selection ${openSelectSubject ? "active" : ""}`}
									onClick={() => setOpenSelectSubject((prev) => !prev)}
									value={selectedSubject}
									onChange={handleSubjectChange}
								>
									<option value="daily">Günlük</option>
									<option value="exam">İmtahan</option>
									<option value="quiz">Quiz</option>
									<option value="project">Disiplin</option>
								</Form.Select>
							</div>
						</div>
					</div>
					<div className="groups-body">
						<table>
							<thead>
								<tr>
									<th>
										<div
											className="left column-content"
											style={{ width: "500px" }}
										>
											Tələbə
										</div>
									</th>
									<th>
										<div className="column-content">Qiymət</div>
									</th>
									<th>
										<div className="column-content">Plus</div>
									</th>
									<th>
										<div className="column-content">Qayıb</div>
									</th>
									<th>
										<div className="right column-content">Qeyd</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{/* {getData &&
									getData.data &&
									getData.data.students &&
									getData.data.students.map((student, i) => (
										<AddGradeGroupTableItem
											data={student}
											allData={allData}
											key={i}
											role={role}
											onUpdate={handleUpdateStudent}
										/>
									))} */}
								{getData &&
									getData.data &&
									getData.data.students &&
									getData.data.students
										.sort((a, b) => (a.full_name || "").localeCompare(b.full_name || ""))
										.map((student, i) => (
											<EditGradeTableItem
												data={student}
												allData={allData}
												key={i}
												role={role}
												onUpdate={handleUpdateStudent}
											/>
										))}
							</tbody>
						</table>
					</div>
				</div>
				{Object.keys(errors).map((key) => (
					<div className="error mt-3" key={key}>
						{errors[key]}
					</div>
				))}
				<div className="grade-btns mb-5">
					<button
						className="btn-grey button"
						type="button"
						onClick={handleGoBack}
					>
						Geriyə
					</button>
					<button
						type="submit"
						className="btn-add button"
						onClick={handleSubmit}
					>
						Yadda saxla
					</button>
				</div>
			</form >
			{showCalendar && (
				<div className="calendar-popup">
					<DatePicker
						selected={selectedDate}
						onChange={handleDateChange}
						inline
					/>
				</div>
			)
			}
		</>
	);
}

export default EditGradeTable;
