import { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import LessonsTableItem from "./LessonsTableItem";
import { lessonsTableHeading, lessonsTableSelect } from "../../utils/data";
import AddGroup from "./AddGroup";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { fetchGroups, createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import * as consts from "../../consts/Consts";
import { useDispatch } from 'react-redux';
import { setGroupName } from '../../store/action';
import AddLesson from "./AddLesson";

const LessonsTable = ({ onSwitchTable, groupId }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [addGroupModalShow, setAddGroupModalShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const currentPage = parseInt(searchParams.get('page')) || 1;
	const pagination = parseInt(searchParams.get('pagination')) || 25;
	const [totalPages, setTotalPages] = useState(0);
	const [sortField, setSortField] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [search, setSearch] = useState('');
	const [data, setData] = useState([])
	const [lessons, setLessons] = useState([]);
	const handleAddGroupModalClose = () => setAddGroupModalShow(false);
	const handleAddGroupModalShow = () => setAddGroupModalShow(true);
	const groupNameFromRedux = useSelector((state) => state.groupName);


	useEffect(() => {
		if (!searchParams.get('page') || !searchParams.get('pagination')) {
			setSearchParams({
				page: currentPage,
				pagination: pagination,
			});
		}
	}, [searchParams, setSearchParams, currentPage, pagination]);

	useEffect(() => {
		if (groupNameFromRedux && groupNameFromRedux !== search) {
			setSearch(groupNameFromRedux);
			setSearchParams({ page: 1, pagination });
		}
	}, [pagination, setSearchParams]);


	const handleGetData = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(`${consts.API_URL}/admin/group/${groupId}`, {
				headers,
			});

			if (!response.ok) {
				throw new Error("Error fetching group data");
			}

			const result = await response.json();

			if (result.data) {
				setData(result.data);
				setLessons(result.data.lessons || []);
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};
	useEffect(() => {
		if (groupId) {
			handleGetData();
		}
	}, [groupId]);

	const handlePageChange = (event, value) => {
		setSearchParams({ page: value, pagination });
	};

	const handlepaginationChange = (event) => {
		setSearchParams({ page: 1, pagination: Number(event.target.value) });
	};

	const handleSortChange = (value) => {
		const [field, order] = value.split(' ');
		setSortField(field);
		setSortOrder(order);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setSearchParams({ page: 1, pagination });
	};

	useEffect(() => {
		// Fetch lessons data based on the selected group ID
		if (groupId) {
			// console.log(`Fetching lessons for group ID: ${groupId}`);
			// Add your data fetching logic here
		}
	}, [groupId]);


	return (
		<>
			<AddLesson show={addGroupModalShow} handleClose={handleAddGroupModalClose} groupId={groupId} />
			<div className="groups lessons">
				<div className="groups-header">
					<div className="header-left d-flex">
						<button className="add-button" onClick={handleAddGroupModalShow}>
							<svg
								width="23"
								height="22"
								viewBox="0 0 23 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="8.5"
									y="8"
									width="13"
									height="13"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M16.5 8V1H1.5V16H8.5"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinejoin="round"
								/>
								<path
									d="M18 14.5H12M15 11.5V17.5"
									stroke="#141B34"
									strokeWidth="1.5"
								/>
							</svg>
							<div>Yeni dərs əlavə et</div>
						</button>
					</div>
					<div className="header-right">
						<FilterSelect data={lessonsTableSelect} onSelect={handleSortChange} />

						<div className="search-box">
							<input
								type="text"
								placeholder="Axtar"
								value={search}
								onChange={handleSearchChange}
							/>
						</div>
					</div>
				</div>
				<div className="groups-body">
					<table>
						<thead>
							<tr>
								{lessonsTableHeading?.map((item, i) => (
									<th key={item.value}>
										<div className={`${i === 0 ? "left" : ""} column-content`}>
											{item.name}
											{sortField === item.value && (
												<span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
											)}
										</div>
									</th>
								))}
								<th>
									<div className="right column-content justify-content-end">Əməliyyatlar</div>
								</th>
							</tr>
						</thead>

						<tbody>
							{lessons?.map((lesson) => (
								<LessonsTableItem
									key={lesson.id}
									lesson={lesson}
									data={data}
									loading={loading}
									setLoading={setLoading}
								/>
							))}
						</tbody>
					</table>
				</div>
				<div className="groups-footer">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						className="pagination"
						variant="outlined"
						shape="rounded"
					/>

					<Form.Select
						className={`selection ${openSelectTwo ? "active" : ""}`}
						value={pagination}
						onChange={handlepaginationChange}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</Form.Select>
				</div>
			</div>
		</>
	);
};

export default LessonsTable;

