import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function ModalGroupArchive({
	show,
	handleClose,
	data,
	isArchived,
}) {
	const navigate = useNavigate();

	const handleSubmit = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(
				`${consts.API_URL}/admin/group/set/archive`,
				{
					method: "POST",
					headers,
					body: JSON.stringify({
						id: data.id,
						is_archived: isArchived == 0 ? 1 : isArchived == 1 ? 0 : null,
					}),
				}
			);
			if (response.ok) {
				handleClose();
				window.location.reload();
			} else {
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} className="student-modal" backdrop="static">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="alert-modal">
					<div className="alert-image">
						<svg
							width="72"
							height="72"
							viewBox="0 0 72 72"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle
								cx="35.9997"
								cy="35.9999"
								r="34.1667"
								strokeWidth="3"
								stroke="#FC5A5A"
							/>
							<path
								d="M35.972 46.25H36.0027"
								stroke="#FC5A5A"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M36 36L36 22.3333"
								stroke="#FC5A5A"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="alert-message">
						<h2 className="alert-title">
							{isArchived == 0
								? "Qrupu arxivləşdirmək istəyirsinizmi?"
								: "Qrupu arxivdən çıxartmaq istəyirsinizmi?"}
						</h2>
						<p className="alert-content">
							{isArchived == 0
								? `${data.name} adlı qrup arxivləşdiriləcək!`
								: `${data.name} adlı qrup arxivdən çıxarılacaq!`}
						</p>
						<div className="buttons mt-3">
							<Button type="button" className="no-button" onClick={handleClose}>
								Xeyr
							</Button>
							<Button
								type="button"
								className="yes-button"
								onClick={handleSubmit}
							>
								Bəli
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}

export default ModalGroupArchive;
