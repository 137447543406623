export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_SELECTED_GROUP_SUBJECT = 'SET_SELECTED_GROUP_SUBJECT';
export const SET_SELECTED_SUBJECT = 'SET_SELECTED_SUBJECT';

export const SET_DATA_STATUS = 'SET_DATA_STATUS';

export const SET_GET_DATA = 'SET_GET_DATA';

export const SET_GROUP_NAME = 'SET_GROUP_NAME';

export const setGetData = (data) => ({
	type: SET_GET_DATA,
	payload: data,
});

export const setDataStatus = (dataStatus) => ({
	type: SET_DATA_STATUS,
	payload: dataStatus,
});
export const setSelectedGroup = (groupId) => {
	localStorage.setItem("selectedGroup", JSON.stringify(groupId))
	return {
		type: SET_SELECTED_GROUP,
		payload: groupId,
	}
};

export const setSelectedGroupSubject = (groupSubjectId) => {
	localStorage.setItem("selectedGroupSubject", JSON.stringify(groupSubjectId));
	return {
		type: SET_SELECTED_GROUP_SUBJECT,
		payload: groupSubjectId,
	}
};

export const setSelectedSubject = (subjectId) => {
	localStorage.setItem("selectedSubject", JSON.stringify(subjectId));
	return {
		type: SET_SELECTED_SUBJECT,
		payload: subjectId,
	}
};

export const setGroupName = (groupName) => ({
	type: SET_GROUP_NAME,
	payload: groupName,
});