import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap'
import { setSelectedGroupSubject } from "../../store/action";
import { handleUnauthorizedError } from "../../functions/authUtils";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import TopIcon from '../TopIcon';

function AverageScore({ data }) {
	const student = data?.[0];
	const totalAverage = student?.total_average?.all_average ?? 'N/A';
	const weeklyAverage = student?.total_average?.week_average ?? 'N/A';

	return (
		(data && (
			<div className="averages">
				<Row>
					<Col md={6}>
						<div className="averages__item averages-total">
							<h4 className="averages__title">Ümumi ortalama</h4>
							<div className="averages__body">
								<div className="averages__score">
									<span className="score-total">{totalAverage}</span>
									<span className="score-100">/ 100</span>
								</div>
								{/* <p className="averages__absent">Ümumi  qayıb sayı: 14
							<span className="absent-count">14</span>
						</p> */}
							</div>
							<div className="averages__icon">
								<TopIcon formattedAvgTotal={totalAverage} />
							</div>
						</div>
					</Col>
					<Col md={6}>
						<div className="averages__item averages-weekly">
							<div className="d-flex align-items-center justify-content-between">
								<h4 className="averages__title">Həftəlik  ortalama</h4>
							</div>
							<div className="averages__body">
								<div className="averages__score">
									<span className="score-total">{weeklyAverage}</span>
									<span className="score-100">/ 100</span>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		))

	)
}

export default AverageScore
