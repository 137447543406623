import React from "react";
import {
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

const data = [
	{ name: "21.01.25", uv: 60 },
	{ name: "22.01.25", uv: 70 },
	{ name: "23.01.25", uv: 50 },
	{ name: "24.01.25", uv: 89 },
	{ name: "25.01.25", uv: 85 },
	{ name: "26.01.25", uv: 76 },
	{ name: "27.01.25", uv: 90 },
];

const LineChartStudent = () => {
	return (
		<div style={{ backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "20px" }}>
			<h3 style={{ color: "#171725", fontSize: "16px", fontWeight: "600" }}>Akademik irəliləyiş</h3>
			<ResponsiveContainer width="100%" height={400}>
				<LineChart
					data={data}
					margin={{ top: 20, right: 30, left: 20, bottom: 20 }} // Добавлен отступ снизу
					style={{ backgroundColor: "#FFFFFF" }}
				>
					<CartesianGrid stroke="#f5f5f5" horizontal={false} /> {/* Убираем вертикальные линии */}
					<XAxis
						dataKey="name"
						tick={{ fill: "#92929D" }}
						axisLine={false}
						tickLine={false}
					/>
					<YAxis
						ticks={[0, 20, 40, 60, 80, 100]} // Значения оси Y
						tick={{ fill: "#92929D" }} // Цвет текста оси Y
						axisLine={false} // Убираем линию оси
						tickLine={false} // Убираем маленькие линии возле текста
						padding={{ bottom: 20 }} // Отступ между осью X и Y
					/>
					<Tooltip
						cursor={{ stroke: "#0062FF", strokeWidth: 3 }} // Цвет и толщина линии курсора
						contentStyle={{ borderRadius: "8px", border: "1px solid #0062FF" }} // Стили тултипа
						itemStyle={{ color: "#0062FF" }} // Цвет текста внутри тултипа

					/>
					<Line
						type="monotone"
						dataKey="uv"
						stroke="#17E5BE"
						strokeWidth={3} // Увеличиваем толщину линии
						activeDot={{ r: 6 }} // Увеличиваем радиус активной точки
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default LineChartStudent;
