import { Modal } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { fetchTeacherGroups } from "../../functions/apiService";

const CreateExercise = ({ show, handleClose, id, data, role }) => {
	const navigate = useNavigate();

	const handleCloseModal = () => {
		handleClose();
	};

	const [groups, setGroups] = useState([]);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (role === "teacher") {
					const teacherData = await fetchTeacherGroups(navigate);
					setGroups(teacherData);
				}
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [role, navigate]);

	const findGroupNameById = useMemo(
		() => (groupId) => {
			const groupItem = groups?.find(item => item.id === groupId);
			console.log(groupItem)
			return groupItem ? groupItem?.name : "";
		},
		[groups]
	);


	return (
		<>
			<Modal show={show} onHide={handleCloseModal} className="create-exercise" backdrop="static">
				<Modal.Header closeButton className="modal-header-divider">
					<div className="modal-title">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4 9.48513L8.57372 14L16 5"
								stroke="#696974"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>

						<span>Tapşırıq</span>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-body-divider">
					<div className="mb-header-wrapper">
						<div className="mb-header">
							<h2 className="mb-3">{data && data?.name}</h2>
							<span>Fənn: {data.subject?.translations?.[0]?.name || "Məlumat yoxdur"}</span>
						</div>

						<div className="mb-header-details row">
							<div className="hd_left col-6">
								<h6 className="hd_title">Qrup</h6>
								{role === 'teacher' ? (
									<div className="student-profile hd-content">

										{data && data.assignments && data.assignments.length > 0 ? (
											data.assignments.map((assignment) =>
												assignment.group_subject ? (
													<OverlayTrigger
														key={assignment.group_subject.id}
														placement="right"
														className="hover-tooltip"
														delay={{ show: 250, hide: 400 }}
														overlay={
															<Tooltip
																id={`tooltip-${assignment.group_subject.id}`}
																className="tooltip-wrapper"
															>
																{findGroupNameById(assignment.group_subject.id, groups)}
															</Tooltip>
														}
													>
														<div className="group-profile">
															{/* <span>
																			{group.name
																				.split(" ")
																				.map((word) => word.charAt(0).toUpperCase())
																				.join("")}{" "}
																		</span> */}
															<span>
																{findGroupNameById(assignment.group_subject.id, groups)
																	?.split(" ")
																	.map((word) => word.charAt(0).toUpperCase())
																	.join("") || ""}
															</span>
														</div>
													</OverlayTrigger>
												) : (
													<span key={assignment.id}>Qruplar barədə məlumat yoxdur</span>
												)
											)
										) : (
											<span>Qrup yoxdur</span>
										)}
									</div>
								)
									:
									(
										<div className="student-profile hd-content">

											{data && data.assignments && data.assignments.length > 0 ? (
												data.assignments.map((assignment) =>
													assignment.group_subject && assignment.group_subject.group ? (
														<OverlayTrigger
															key={assignment.group_subject.id}
															placement="right"
															className="hover-tooltip"
															delay={{ show: 250, hide: 400 }}
															overlay={
																<Tooltip
																	id={`tooltip-${assignment.group_subject.group.id}`}
																	className="tooltip-wrapper"
																>
																	{assignment.group_subject.group.name}
																	{/* {findGroupNameById(assignment.group_subject.group.name, groups)} */}
																</Tooltip>
															}
														>
															<div className="group-profile">
																{/* <span>
																			{group.name
																				.split(" ")
																				.map((word) => word.charAt(0).toUpperCase())
																				.join("")}{" "}
																		</span> */}
																<span>
																	{assignment.group_subject.group.name
																		?.split(" ")
																		.map((word) => word.charAt(0).toUpperCase())
																		.join("") || ""}
																</span>
															</div>
														</OverlayTrigger>
													) : (
														<span key={assignment.id}>Qruplar barədə məlumat yoxdur</span>
													)
												)
											) : (
												<span>Qrup yoxdur</span>
											)}
										</div>
									)
								}
							</div>
							<div className="hd_right col-6">
								<h6 className="hd_title">Başlama və bitmə tarixi</h6>
								<div className="hd-content">
									<div>
										<span>
											{data && data?.start_date} - {data && data?.end_date}
										</span>
									</div>

									<div>
										<svg
											width="23"
											height="23"
											viewBox="0 0 23 23"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M16.7707 1.91675V5.75008M6.229 1.91675V5.75008"
												stroke="#707070"
												strokeWidth="1.5"
												strokeLinejoin="round"
											/>
											<path
												d="M20.125 3.8335H2.875V21.0835H20.125V3.8335Z"
												stroke="#707070"
												strokeWidth="1.5"
												strokeLinejoin="round"
											/>
											<path
												d="M10.5522 12.9375H15.3439M7.67725 12.9375H7.68585M12.4689 16.7708H7.67725M15.3439 16.7708H15.3353"
												stroke="#707070"
												strokeWidth="1.5"
												strokeLinecap="square"
												strokeLinejoin="round"
											/>
											<path
												d="M2.875 8.625H20.125"
												stroke="#707070"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer style={{ display: "block" }}>
					<div className="mf-wrapper">
						<div className="mf-top">
							<div className="mf-title mb-3">
								<svg
									width="18"
									height="17"
									viewBox="0 0 18 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M17 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2ZM17 7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7ZM1 12H17C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10H1C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12ZM11 17H1C0.447715 17 0 16.5523 0 16C0 15.4477 0.447715 15 1 15H11C11.5523 15 12 15.4477 12 16C12 16.5523 11.5523 17 11 17Z"
										fill="#92929D"
									/>
								</svg>
								<h6>Tapşırığın məzmunu</h6>
							</div>

							<div className="content-wrapper">
								<p dangerouslySetInnerHTML={{ __html: data && data.content }}></p>
							</div>
						</div>
						<div className="mf-bottom">
							<div className="mf-title mb-3">
								<svg
									width="16"
									height="18"
									viewBox="0 0 16 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M1.71391 7.79446L8.23917 1.2692C9.93144 -0.423068 12.6752 -0.423068 14.3674 1.2692C16.0597 2.96148 16.0597 5.70519 14.3674 7.39746L7.84217 13.9227C6.79726 14.9205 5.14126 14.9062 4.11438 13.8793C3.07298 12.8379 3.07298 11.1494 4.11438 10.108L10.5962 3.62623C10.9867 3.2357 11.6199 3.2357 12.0104 3.62623C12.4009 4.01675 12.4009 4.64992 12.0104 5.04044L5.5286 11.5223C5.26825 11.7826 5.26825 12.2047 5.5286 12.4651C5.78895 12.7254 6.21105 12.7254 6.4714 12.4651L12.9532 5.98325C13.8644 5.07203 13.8644 3.59464 12.9532 2.68342C12.042 1.77219 10.5646 1.77219 9.65339 2.68342L3.17157 9.16523C1.60948 10.7273 1.60948 13.26 3.17157 14.8221C4.73367 16.3842 7.26633 16.3842 8.82843 14.8221L14.1317 9.51878C14.5223 9.12826 15.1554 9.12826 15.5459 9.51878C15.9365 9.90931 15.9365 10.5425 15.5459 10.933L10.2426 16.2363C7.89949 18.5794 4.1005 18.5794 1.75736 16.2363C-0.571287 13.9077 -0.585697 10.1411 1.71413 7.79468L1.71391 7.79446Z"
										fill="#92929D"
									/>
								</svg>
								<h6 className="bold-title">Attachment</h6>
							</div>
							{data && data.attachments && (
								<div>
									<ul className="file-display-list">
										{data.attachments.map((file, id) => (
											<li key={id} className="d-flex justify-content-between">
												<Link
													to={file.path}
													title={file.name}
													className="file-display-inner"
													target="_blank"
												// onClick={() => openFileInNewTab(file.path)}
												>
													<div className="file-display">
														{file.path && file.path.includes("doc") && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="60"
																height="73"
																viewBox="0 0 30 20"
																color="#B3B3B3"
																fill="none"
															>
																<path
																	d="M21 15.0163C20.9544 14.0244 20.2766 14 19.3571 14C17.9407 14 17.7059 14.3384 17.7059 15.6667V17.3333C17.7059 18.6616 17.9407 19 19.3571 19C20.2766 19 20.9544 18.9756 21 17.9837M10.2949 16.5C10.2949 17.8807 9.18876 19 7.82429 19C7.51642 19 7.36248 19 7.24782 18.933C6.9733 18.7726 7.00076 18.448 7.00076 18.1667V14.8333C7.00076 14.552 6.9733 14.2274 7.24782 14.067C7.36248 14 7.51642 14 7.82429 14C9.18876 14 10.2949 15.1193 10.2949 16.5ZM14 19C13.2236 19 12.8354 19 12.5941 18.7559C12.3529 18.5118 12.3529 18.119 12.3529 17.3333V15.6667C12.3529 14.881 12.3529 14.4882 12.5941 14.2441C12.8354 14 13.2236 14 14 14C14.7764 14 15.1646 14 15.4059 14.2441C15.6471 14.4882 15.6471 14.881 15.6471 15.6667V17.3333C15.6471 18.119 15.6471 18.5118 15.4059 18.7559C15.1646 19 14.7764 19 14 19Z"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																/>
																<path
																	d="M19 11C19 10 19 9.4306 18.8478 9.06306C18.6955 8.69552 18.4065 8.40649 17.8284 7.82843L13.0919 3.09188C12.593 2.593 12.3436 2.34355 12.0345 2.19575C11.9702 2.165 11.9044 2.13772 11.8372 2.11401C11.5141 2 11.1614 2 10.4558 2C7.21082 2 5.58831 2 4.48933 2.88607C4.26731 3.06508 4.06508 3.26731 3.88607 3.48933C3 4.58831 3 6.21082 3 9.45584V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H19M12 2.5V3C12 5.82843 12 7.24264 12.8787 8.12132C13.7574 9 15.1716 9 18 9H18.5"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														)}

														{file.path && file.path.includes("zip") && (
															<svg
																width="45"
																height="45"
																viewBox="0 0 60 73"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M50.5384 72.8594H9.41332C7.02615 72.8565 4.73757 71.907 3.04958 70.219C1.3616 68.531 0.412033 66.2424 0.40918 63.8552V9.62914C0.412033 7.24197 1.3616 4.95339 3.04958 3.2654C4.73757 1.57742 7.02615 0.627853 9.41332 0.625H36.3844C36.7384 0.62484 37.089 0.694421 37.4161 0.82977C37.7432 0.965119 38.0405 1.16358 38.2909 1.41383L58.7537 21.8766C59.004 22.127 59.2024 22.4243 59.3378 22.7514C59.4731 23.0785 59.5427 23.4291 59.5425 23.7831V63.8534C59.5402 66.2409 58.5908 68.53 56.9028 70.2183C55.2147 71.9067 52.9259 72.8565 50.5384 72.8594ZM9.41332 6.01563C8.45525 6.01658 7.53669 6.39759 6.85923 7.07505C6.18177 7.75251 5.80076 8.67107 5.7998 9.62914V63.8552C5.80076 64.8133 6.18177 65.7319 6.85923 66.4093C7.53669 67.0868 8.45525 67.4678 9.41332 67.4688H50.5384C51.4965 67.4678 52.415 67.0868 53.0925 66.4093C53.77 65.7319 54.151 64.8133 54.1519 63.8552V24.899L35.2686 6.01563H9.41332Z"
																	fill="#B3B3B3"
																/>
																<path
																	d="M56.8471 26.4784H42.6931C40.3059 26.4756 38.0174 25.526 36.3294 23.838C34.6414 22.15 33.6918 19.8615 33.689 17.4743V3.32031C33.689 2.60547 33.9729 1.91991 34.4784 1.41444C34.9839 0.90897 35.6694 0.625 36.3843 0.625C37.0991 0.625 37.7847 0.90897 38.2902 1.41444C38.7956 1.91991 39.0796 2.60547 39.0796 3.32031V17.4743C39.0805 18.4324 39.4616 19.3509 40.139 20.0284C40.8165 20.7058 41.735 21.0869 42.6931 21.0878H56.8471C57.5619 21.0878 58.2475 21.3718 58.753 21.8773C59.2584 22.3827 59.5424 23.0683 59.5424 23.7831C59.5424 24.498 59.2584 25.1835 58.753 25.689C58.2475 26.1945 57.5619 26.4784 56.8471 26.4784Z"
																	fill="#B3B3B3"
																/>
																<path
																	d="M18.7034 53.0758H25.1721C25.8909 53.0758 26.2898 53.9329 26.2898 54.693C26.2898 55.453 25.8855 56.3101 25.1721 56.3101H15.0396C14.2777 56.3101 13.9614 55.9058 13.9614 55.3362C13.9843 54.8732 14.1234 54.4233 14.3657 54.0281L21.0735 42.158H15.5624C14.8437 42.158 14.4448 41.3979 14.4448 40.5408C14.4448 39.7573 14.7772 38.9236 15.5624 38.9236H24.7445C25.4632 38.9236 25.8226 39.3279 25.8226 39.8993C25.7971 40.3619 25.6582 40.8111 25.4183 41.2074L18.7034 53.0758Z"
																	fill="#B3B3B3"
																/>
																<path
																	d="M27.7881 40.0412C27.7881 39.2578 28.7153 38.9236 29.6425 38.9236C30.5696 38.9236 31.4986 39.2578 31.4986 40.0412V55.1691C31.4986 55.931 30.5714 56.3101 29.6425 56.3101C28.7135 56.3101 27.7881 55.931 27.7881 55.1691V40.0412Z"
																	fill="#B3B3B3"
																/>
																<path
																	d="M34.0664 40.0412C34.0664 39.4465 34.6145 38.9236 35.4464 38.9236H40.3698C43.509 38.9236 45.9833 40.3988 45.9833 44.3951V44.5137C45.9833 48.5099 43.4137 50.0319 40.1327 50.0319H37.777V55.1691C37.777 55.931 36.8498 56.3101 35.9226 56.3101C34.9954 56.3101 34.0664 55.931 34.0664 55.1691V40.0412ZM37.777 42.158V47.1766H40.1327C41.4641 47.1766 42.2727 46.4165 42.2727 44.7994V44.537C42.2727 42.9198 41.4641 42.158 40.1327 42.158H37.777Z"
																	fill="#B3B3B3"
																/>
															</svg>
														)}

														{file.path && file.path.includes("pdf") && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="60"
																height="73"
																viewBox="0 0 30 20"
																color="#B3B3B3"
																fill="none"
															>
																<path
																	d="M19 11C19 10.1825 19 9.4306 18.8478 9.06306C18.6955 8.69552 18.4065 8.40649 17.8284 7.82843L13.0919 3.09188C12.593 2.593 12.3436 2.34355 12.0345 2.19575C11.9702 2.165 11.9044 2.13772 11.8372 2.11401C11.5141 2 11.1614 2 10.4558 2C7.21082 2 5.58831 2 4.48933 2.88607C4.26731 3.06508 4.06508 3.26731 3.88607 3.48933C3 4.58831 3 6.21082 3 9.45584V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H19M12 2.5V3C12 5.82843 12 7.24264 12.8787 8.12132C13.7574 9 15.1716 9 18 9H18.5"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M21 14H19C18.4477 14 18 14.4477 18 15V16.5M18 16.5V19M18 16.5H20.5M7 19V17M7 17V14H8.5C9.32843 14 10 14.6716 10 15.5C10 16.3284 9.32843 17 8.5 17H7ZM12.5 14H13.7857C14.7325 14 15.5 14.7462 15.5 15.6667V17.3333C15.5 18.2538 14.7325 19 13.7857 19H12.5V14Z"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														)}

														{file.path &&
															(file.path.includes("jpg") ||
																file.path.includes("jpeg") ||
																file.path.includes("png")) && (
																<img
																	className="file-display-img"
																	src={file.path}
																	alt=""
																/>
															)}
													</div>
													<span className="file-display-name">
														{file.name}, {file.size}
													</span>
												</Link>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CreateExercise;
