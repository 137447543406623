import React, { useState } from "react";
import ArchivedGroupsTable from "../components/admin/ArchivedGroupsTable";

function ArchivedGroups() {

	return (
		<section className="group-admin lessons">
			<h3 className="title mb-3">Arxivləşdirilmiş qruplar</h3>
			<ArchivedGroupsTable />
		</section>
	);
}

export default ArchivedGroups;
