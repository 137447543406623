import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { fetchContact } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import InputMask from "react-input-mask";

const ContactModal = ({ show, handleClose }) => {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		web_site: "",
		facebook: "",
		instagram: "",
		linkedin: "",
		youtube: "",
	});

	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(true);

	const resetFormData = () => {
		setFormData({
			web_site: "",
			facebook: "",
			instagram: "",
			linkedin: "",
			youtube: "",
		});
		setErrors({});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		if (value.trim() && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};

	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchContact(navigate);
				if (data) {
					setFormData({
						web_site: data.web_site || "",
						facebook: data.facebook || "",
						instagram: data.instagram || "",
						linkedin: data.linkedin || "",
						youtube: data.youtube || "",
						whatsapp: data.whatsapp || "",
					});
				}
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const handleSubmit = async () => {
		const headers = createHeaders();

		const dataToSend = {
			web_site: formData.web_site,
			facebook: formData.facebook,
			instagram: formData.instagram,
			linkedin: formData.linkedin,
			youtube: formData.youtube,
			whatsapp: formData.whatsapp,
		};

		try {
			const response = await fetch(`${consts.API_URL}/admin/contact`, {
				method: "PUT",
				headers: headers,
				body: JSON.stringify(dataToSend),
			});

			const responseData = await response.json();
			console.log("Ответ от API:", responseData);

			if (response.ok) {
				console.log("Данные успешно обновлены:", responseData);
				handleCloseModal();
				window.location.reload();
			} else {
				console.error("Ошибка при обновлении данных:", responseData.message);
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleCloseModal} backdrop="static">
				<Modal.Header closeButton>
					<div className="modal-title">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4 9.48513L8.57372 14L16 5"
								stroke="#696974"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span>Əlaqə məlumatları</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row className="mb-4">
							<Col>
								<Form.Group controlId="formInput1">
									<Form.Label>Vebsayt</Form.Label>
									<Form.Control
										type="text"
										name="web_site"
										value={formData.web_site}
										onChange={handleChange}
										placeholder="Linki daxil edin"
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="formInput2">
									<Form.Label>Facebook</Form.Label>
									<Form.Control
										type="text"
										name="facebook"
										value={formData.facebook}
										onChange={handleChange}
										placeholder="Linki daxil edin"
									/>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-4">
							<Col>
								<Form.Group controlId="formInput3">
									<Form.Label>YouTube</Form.Label>
									<Form.Control
										type="text"
										name="youtube"
										value={formData.youtube}
										onChange={handleChange}
										placeholder="Linki daxil edin"
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="formInput4">
									<Form.Label>Instagram</Form.Label>
									<Form.Control
										type="text"
										name="instagram"
										value={formData.instagram}
										onChange={handleChange}
										placeholder="Linki daxil edin"
									/>
								</Form.Group>
							</Col>
						</Row>

						<Row className="mb-4">
							<Col>
								<Form.Group controlId="formInput3">
									<Form.Label>Linkedin</Form.Label>
									<Form.Control
										type="text"
										name="linkedin"
										value={formData.linkedin}
										onChange={handleChange}
										placeholder="Linki daxil edin"
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="formInput5">
									<Form.Label>WhatsApp</Form.Label>
									<InputMask
										mask="+999 99 999 99 99"
										value={formData.whatsapp}
										name="whatsapp"
										onChange={handleChange}
										maskChar={null}
									>
										{(inputProps) => (
											<Form.Control
												{...inputProps}
												type="text"
												placeholder="Enter your phone number"
												className={`${errors.whatsapp ? "border-red" : ""}`}
											/>
										)}
									</InputMask>
									{errors.whatsapp && (
										<span className="validation-error" style={{ color: "red" }}>
											{errors.whatsapp}
										</span>
									)}
								</Form.Group>
							</Col>

						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button className="modal-btn btn-green" onClick={handleSubmit}>
						Yadda saxla
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ContactModal;
