import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import AddExerciseModal from './AddExerciseModal';
import { fetchTeacherGroups } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";

function GroupActionModal({ show, handleClose, selectedGroup }) {
	const navigate = useNavigate();
	const location = useLocation();
	const [modalShowAdd, setModalShowAdd] = useState(false);

	const [groups, setGroups] = useState([])
	const [getData, setGetData] = useState(null);

	const [loading, setLoading] = useState(true);

	const [selectedType, setSelectedType] = useState("daily");
	const [sortBy, setSortBy] = useState("sort[students.full_name]");
	const [sortDirection, setSortDirection] = useState("asc");
	const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
	const queryParams = new URLSearchParams(location.search);
	const initialPage = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
	const initialPerPage = queryParams.get('pagination') ? parseInt(queryParams.get('pagination')) : 25;
	const [currentPage, setCurrentPage] = useState(initialPage);
	const [perPage, setPerPage] = useState(initialPerPage);
	const [totalPages, setTotalPages] = useState(0);

	const handleNavigateToGrade = () => {
		// navigate(`/add-grade/${groupId}`);
		handleClose();

		navigate(`/add-grade/${selectedGroup}`, {
			state: {
				getData: getData,
				selectedGroup: selectedGroup,
				groups: groups,
			},
		});
	};

	const handleNavigateToAssignment = () => {
		setModalShowAdd(true);
		handleClose();
	};

	const handleModalCloseAdd = () => {
		setModalShowAdd(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchTeacherGroups(navigate);
				const sortedData = data.sort((a, b) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();
					return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
				});
				setGroups(sortedData);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	useEffect(() => {
		if (selectedGroup && selectedType) {
			const headers = createHeaders();
			const requestData = {
				group_id: selectedGroup,
				date: currentDate,
				status: selectedType,
			};

			fetch(
				`${consts.API_URL}/teacher/group/get/student`,
				{
					method: "POST",
					headers: headers,
					body: JSON.stringify(requestData),
				}
			)
				.then((response) => {
					if (!response.ok) throw new Error("Ошибка сети");
					return response.json();
				})
				.then((data) => {
					setGetData(data);
					setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					console.error("Ошибка при отправке запроса:", error);
				});
		}
	}, [
		selectedGroup,
		selectedType,
		currentDate,
		perPage,
		currentPage,
	]);


	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	return (
		<>
			<AddExerciseModal show={modalShowAdd} handleClose={handleModalCloseAdd} />
			<Modal show={show} onHide={handleClose} className='modal-group' backdrop="static">
				<Modal.Header closeButton />
				<Modal.Body>
					<Button variant="link" onClick={handleNavigateToGrade} className="modal-group-btn d-block w-100 text-start p-2">
						<svg
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7 0H15C18.866 0 22 3.13401 22 7V15C22 18.866 18.866 22 15 22H7C3.13401 22 0 18.866 0 15V7C0 3.13401 3.13401 0 7 0ZM7 2C4.23858 2 2 4.23858 2 7V15C2 17.7614 4.23858 20 7 20H15C17.7614 20 20 17.7614 20 15V7C20 4.23858 17.7614 2 15 2H7ZM7.69164 10.2784L9.96275 12.5203L14.2178 7.36355C14.5693 6.93756 15.1996 6.87718 15.6256 7.22868C16.0516 7.58018 16.1119 8.21046 15.7604 8.63645L10.8096 14.6364C10.4357 15.0895 9.75378 15.1243 9.33576 14.7117L6.28661 11.7018C5.89356 11.3138 5.88946 10.6806 6.27745 10.2876C6.66544 9.89453 7.29859 9.89043 7.69164 10.2784Z"
								fill="#92929D"
							/>
						</svg>
						Qiymət yaz
					</Button>
					<Button variant="link" onClick={handleNavigateToAssignment} className="modal-group-btn d-block w-100 text-start p-2">
						<svg
							width="22"
							height="21"
							viewBox="0 0 22 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16 2V1C16 0.447715 16.4477 0 17 0C17.5523 0 18 0.447715 18 1V2H19C20.6569 2 22 3.34315 22 5V8V10V15C22 18.3137 19.3137 21 16 21H6C2.68629 21 0 18.3137 0 15V10V8V5C0 3.34315 1.34315 2 3 2H5V1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V2H16ZM2 8H20V5C20 4.44772 19.5523 4 19 4H18C18 4.55228 17.5523 5 17 5C16.4477 5 16 4.55228 16 4H7C7 4.55228 6.55228 5 6 5C5.44772 5 5 4.55228 5 4H3C2.44772 4 2 4.44772 2 5V8ZM2 10V15C2 17.2091 3.79086 19 6 19H16C18.2091 19 20 17.2091 20 15V10H2Z"
								fill="#92929D"
							/>
						</svg>
						Tapşırıq əlavə et
					</Button>
				</Modal.Body>
				<Modal.Footer>
					{/* <Button variant="secondary" onClick={handleClose}>
						Bağla
					</Button> */}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default GroupActionModal
