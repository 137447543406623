import React from "react";
import UserProfile from "../UserProfile";
import TopIcon from "../TopIcon";

function TopStudents({ data }) {
	if (!data) {
		return null;
	}
	const student = data.student || {};
	const formattedAvgTotal = data.avg_total ? parseFloat(data.avg_total).toFixed(2) : '0.00';

	return (
		<div className="top__item d-flex align-items-center justify-content-between">
			<div className="top__body d-flex align-items-center">
				<TopIcon formattedAvgTotal={formattedAvgTotal} />
				<div className="top__img">
					{data.students?.image ? (
						<img
							src={data.students?.image}
							alt=""
							className="top-students-image"
						/>
					) : (
						<UserProfile imageSize={24} containerSize={38} />
					)}
				</div>
				<div className="top__member">
					<h5 className="top__name">{data.students?.full_name || 'Unknown Student'}</h5>
					<p className="top__group">{data.subject?.name || 'No Subject'}</p>
				</div>
			</div>

			<div className="top__score">{formattedAvgTotal}</div>
		</div>
	);
}

export default TopStudents;
