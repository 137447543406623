import React, { useState } from "react";
import LessonsTable from "../components/admin/LessonsTable";

function Lessons() {

	return (
		<section className="group-admin">
			<h3 className="title mb-3">Dərslər</h3>
			<LessonsTable />
		</section>
	);
}

export default Lessons;

