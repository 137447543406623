import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

function EditNoteModal({ show, handleClose }) {
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Form submitted");
		handleClose();
	};

	const [value, setValue] = useState("");
	return (
		<>
			<Modal show={show} onHide={handleClose} className="note-modal" backdrop="static">
				<Modal.Header closeButton></Modal.Header>
				<Form onSubmit={handleSubmit}>
					<Modal.Body>
						<Form.Group
							controlId="exampleForm.ControlTextarea1"
							className="mb-3 message-container"
						>
							<Form.Label className="note-midal-label">Qeyd seç</Form.Label>
							<Form.Control
								type="text"
								value={value || ""}
								onChange={(e) => setValue(e.target.value)}
								placeholder="Mesaj seç..."
							/>

							{value && (
								<div className="auto-complete_container">
									<ul className="ac_list">
										{["salam", "hello", "lorem", "ipsum", "dolor"]
											.filter((v) => v.includes(value))
											.map((item, i) => (
												<li key={i} onClick={() => setValue(item)}>
													{item}
												</li>
											))}
									</ul>
								</div>
							)}
						</Form.Group>

						<Form.Group controlId="exampleForm.ControlTextarea1">
							<Form.Label className="note-midal-label">
								Qeyddə düzəliş et
							</Form.Label>
							<Form.Control
								as="textarea"
								rows={7}
								placeholder="Mesajınızı yazın..."
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button type="submit" className="note-btn modal-btn btn-green">
							Düzəliş et
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
}

export default EditNoteModal;
