import React from 'react'
import GroupsActivityTable from '../components/admin/GroupsActivityTable'

function GroupsActivity() {
	return (
		<section>
			<h3 className="title mb-3">Qrupun aktivliyi</h3>
			<GroupsActivityTable />
		</section>
	)
}

export default GroupsActivity
