import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedGroup, setSelectedGroupSubject } from "../../store/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService"
import { fetchStudentGroups } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import GradeTableItemStudent from "./GradeTableItemStudent";

function GradeTableStudent({ role, startTime, endTime }) {
	const navigate = useNavigate();
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [openSelectType, setOpenSelectType] = useState(false);
	const [openSelectAttendance, setOpenSelectAttendance] = useState(false);

	const [currentWeek, setCurrentWeek] = useState(
		getCurrentWeekRange(new Date())
	);
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const calendarRef = useRef(null);
	const dispatch = useDispatch();
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [getData, setGetData] = useState([]);
	const [allStudents, setAllStudents] = useState(null);
	const [selectedType, setSelectedType] = useState("");
	const [selectedAttendance, setSelectedAttendance] = useState("");
	const [sortBy, setSortBy] = useState("date");
	const [sortDirection, setSortDirection] = useState("asc");

	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	function getCurrentWeekRange(date) {
		const today = new Date(date);
		const dayOfWeek = today.getDay();

		const startOfWeek = new Date(today);
		startOfWeek.setDate(
			today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
		);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		return {
			start: formatDate(startOfWeek),
			end: formatDate(endOfWeek),
		};
	}

	function formatDate(date) {
		return date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
	}

	function parseDate(dateString) {
		const [day, month, year] = dateString.split(".");
		return new Date(`${year}-${month}-${day}`);
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setCurrentWeek(getCurrentWeekRange(date));
		setShowCalendar(false);
	};

	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target)) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (groups && groups.length > 0) {
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups[0].id));
			}
		}
	}, [groups, selectedGroup, dispatch]);


	const handleTypeChange = (e) => {
		setSelectedType(e.target.value);
	};

	const handleAttendanceChange = (e) => {
		const value = e.target.value;
		setSelectedAttendance(value === "" ? null : value);
	};

	const handlePerPageChange = (event) => {
		setPerPage(Number(event.target.value));
		setCurrentPage(1);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const handleSortChange = (e) => {
		const value = e.target.value;
		const [field, direction] = value.split("=");
		if (field === sortBy) {
			setSortDirection((prevDirection) =>
				prevDirection === "asc" ? "desc" : "asc"
			);
		} else {
			setSortBy(field);
			setSortDirection(direction);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchStudentGroups(navigate);
				setGroups(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	useEffect(() => {
		if (selectedGroup && selectedGroupSubject && startTime && endTime) {
			const headers = createHeaders();

			const requestParams = new URLSearchParams({
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: startTime,
				date_to: endTime,
				pagination: perPage,
				page: currentPage,
				// ...(sortBy ? { [sortBy]: sortDirection } : {}), 
			});

			if (selectedAttendance) {
				requestParams.append('attendance', selectedAttendance);
			} else {
				requestParams.delete('attendance'); 
			}

			if (selectedType) {
				requestParams.append('status', selectedType);
			} else {
				requestParams.delete('status'); 
			}

			fetch(`${consts.API_URL}/student/grade?${requestParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data.data);
					setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});

			const allStudentsParams = new URLSearchParams({
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: startTime,
				date_to: endTime,
				pagination: perPage,
				// pagination: perPage,
			});

			if (selectedAttendance) {
				allStudentsParams.append('attendance', selectedAttendance);
			} else {
				allStudentsParams.delete('attendance');
			}

			if (selectedType) {
				allStudentsParams.append('status', selectedType);
			} else {
				allStudentsParams.delete('status'); 
			}


			fetch(`${consts.API_URL}/student/grade?${allStudentsParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data.data);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});
		}
	}, [
		selectedGroup,
		selectedGroupSubject,
		selectedType,
		selectedAttendance,
		startTime,
		endTime,
		perPage,
		currentPage,
		// sortBy,
		// sortDirection,
	]);

	return (
		<>
			<div className="form-performance mt-5 mb-5">
				<h3 className="title">Performans cədvəli</h3>
				<div className="groups">
					<div className="groups-header groups-header-grade d-block">
						{/* <div className="header-left">
					<h3 className="title mb-3">Müəllimin qeydləri</h3>
				</div> */}
						<div className="header-right justify-content-between">
							<div className="group-subject d-flex align-items-center">
								<Form.Select
									className={`selection ${openSelectAttendance ? "active" : ""}`}
									onClick={() => setOpenSelectAttendance((prev) => !prev)}
									onChange={handleAttendanceChange}
								>
									<option value="">Ümumi</option>
									<option value="1">Qiymət</option>
									<option value="0">Davamiyyət</option>
								</Form.Select>
							</div>
							<div className="d-flex align-items-center grade-week">
								<div className="group-subject d-flex align-items-center">
									<p className="group-subject-name">Qiymət tipi: </p>
									<Form.Select
										className={`selection ${openSelectType ? "active" : ""}`}
										onClick={() => setOpenSelectType((prev) => !prev)}
										onChange={handleTypeChange}
									>
										<option value="">Ümumi</option>
										<option value="daily">Günlük</option>
										<option value="exam">İmtahan</option>
										<option value="quiz">Quiz</option>
										<option value="project">Disiplin</option>
									</Form.Select>
								</div>
							</div>
						</div>
					</div>
					<div className="groups-body">
						<table>
							<thead>
								<tr>
									<th>
										<div className="left column-content">
											Tarix
										</div>
									</th>
									<th>
										<div className="column-content" >
											Qiymət
										</div>
									</th>
									<th>
										<div className="column-content">Qiymət tipi</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{getData?.map((student, i) => (
									<GradeTableItemStudent data={student} key={i} role={role} />
								))}
							</tbody>
						</table>
					</div>
					<div className="groups-footer">
						<div className="groups-pagination d-flex">
							<Pagination
								count={totalPages}
								page={currentPage}
								onChange={handlePageChange}
								className="pagination"
								variant="outlined"
								shape="rounded"
							/>

							<Form.Select
								className={`selection ${openSelectTwo ? "active" : ""}`}
								onClick={() => setOpenSelectTwo((prev) => !prev)}
								onChange={handlePerPageChange}
							>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</Form.Select>
						</div>
					</div>

				</div>
			</div>
		</>
	);
}

export default GradeTableStudent;
