import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap'
import AverageScore from '../components/students/AverageScore'
import TopStudent from '../components/TopStudent'
import GradeTableStudent from '../components/students/GradeTableStudent'
import { setSelectedGroup, setSelectedGroupSubject } from "../store/action";
import { handleUnauthorizedError } from "../functions/authUtils";
import Form from "react-bootstrap/Form";
import { fetchStudentGroups, fetchSettingsGrade } from "../functions/apiService";
import { OverlayTrigger, Popover } from "react-bootstrap";
import NotesTableStudent from '../components/students/NotesTableStudent';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as consts from "../consts/Consts";
import { createHeaders } from "../functions/apiService"
import LineChartStudent from '../components/students/LineChartStudent'
import PieChartStudent from '../components/students/PieChartStudent'
import SemicircleProgressBarStudent from '../components/students/SemicircleProgressBarStudent'

function GradeStudent() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [openSelectGroup, setOpenSelectGroup] = useState(false);
	const [openSelectLesson, setOpenSelectLesson] = useState(false);
	const [groups, setGroups] = useState([]);
	const selectedGroup = useSelector((state) => state.selectedGroup);
	const selectedGroupSubject = useSelector((state) => state.selectedGroupSubject);
	const [loading, setLoading] = useState(true);
	const [showCalendar, setShowCalendar] = useState(false);
	const calendarRef = useRef(null);
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());
	const [lessons, setLessons] = useState([]);
	const [lessonClicked, setLessonClicked] = useState(false);
	const [getData, setGetData] = useState(null);


	const handleToggleCalendar = () => {
		setShowCalendar((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (calendarRef.current && !calendarRef.current.contains(event.target) && !event.target.closest('.week-current')) {
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const formatDate = (date) => {
		return date ? date.toLocaleDateString('ru-RU') : '';
	};

	const formattedStartTime = formatDate(startTime);
	const formattedEndTime = formatDate(endTime);

	useEffect(() => {
		if (selectedGroup) {
			const selectedGroupData = groups.find((group) => group.id === Number(selectedGroup));
			setLessons(selectedGroupData ? selectedGroupData.lessons : []);
		} else if (groups && groups.length > 0) {
			dispatch(setSelectedGroup(groups[0].id));
		}
	}, [groups, selectedGroup, dispatch]);

	useEffect(() => {
		if (groups && groups.length > 0) {
			if (!lessonClicked) {
				dispatch(setSelectedGroupSubject(lessons[0]?.id));
			}
			if (!selectedGroup) {
				dispatch(setSelectedGroup(groups[0]?.id));
			}
			if (!selectedGroupSubject) {
				dispatch(setSelectedGroupSubject(lessons[0]?.id));
			}
		}
	}, [groups, lessons, selectedGroup, selectedGroupSubject, dispatch]);


	const handleGroupChange = (e) => {
		setLessonClicked(false)
		const groupId = Number(e.target.value);
		dispatch(setSelectedGroup(groupId));

		const selectedGroupData = groups.find((group) => group.id === groupId);
		if (selectedGroupData) {
			dispatch(setSelectedGroupSubject(lessons[0].id));
		}
	};

	// const handleGroupChange = (e) => {
	// 	const groupId = e.target.value;
	// 	dispatch(setSelectedGroup(groupId));

	// 	const selectedGroupData = groups.find((group) => group.id === Number(groupId));

	// 	setLessons(selectedGroupData ? selectedGroupData.lessons : []);
	// };

	const handleLessonChange = (e) => {
		const groupSubjectId = Number(e.target.value);

		if (!lessonClicked) {
			setLessonClicked(true);
		}

		dispatch(setSelectedGroupSubject(groupSubjectId));
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchStudentGroups(navigate);
				setGroups(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const [percents, setPercents] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSettingsGrade(navigate);
				setPercents(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (selectedGroup && selectedGroupSubject && formattedStartTime && formattedEndTime) {
			const headers = createHeaders();

			const requestParams = new URLSearchParams({
				group_id: selectedGroup,
				group_subject_id: selectedGroupSubject,
				date_from: formattedStartTime,
				date_to: formattedEndTime,
				// pagination: perPage,
				// page: currentPage,
				// ...(sortBy ? { [sortBy]: sortDirection } : {}), 
			});

			fetch(`${consts.API_URL}/student/grade?${requestParams.toString()}`, {
				method: "GET",
				headers: headers,
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error("Ошибка сети");
					}
					return response.json();
				})
				.then((data) => {
					setGetData(data.data);
					// setTotalPages(data.pagination.total_pages);
				})
				.catch((error) => {
					// console.error("Ошибка при отправке запроса:", error);
				});

		}
	}, [
		selectedGroup,
		selectedGroupSubject,
		startTime,
		endTime,
		// sortBy,
		// sortDirection,
	]);

	const renderSchedule = (groupId) => {
		const weekDayTranslations = {
			monday: "Bazar ertəsi",
			tuesday: "Çərşənbə axşamı",
			wednesday: "Çərşənbə",
			thursday: "Cümə axşamı",
			friday: "Cümə",
			saturday: "Şənbə",
			sunday: "Bazar",
		};
		const selectedGroupData = groups.find((group) => group.id === Number(groupId));

		if (!selectedGroupData || !selectedGroupData.lessons || selectedGroupData.lessons.length === 0) {
			return <p>Cədvəl mövcud deyil</p>;
		}

		return (
			<table className="turn-wrapper">
				<tbody>
					{selectedGroupData.lessons.map((lesson) => {
						if (!lesson.schedules || lesson.schedules.length === 0) {
							return null;
						}

						return lesson.schedules.map((schedule, index) => (
							<tr key={`${lesson.id}-${schedule.id}`}>
								<td>
									<span className="turn-left">
										{`${weekDayTranslations[schedule.week_day]}:` || schedule.week_day}
									</span>
								</td>
								<td>
									<span className="turn-right">
										<div>
											{schedule.start_time?.slice(0, 5)} - {schedule.end_time?.slice(0, 5)}
										</div>
									</span>
								</td>
							</tr>
						));
					})}
				</tbody>
			</table>
		);
	};

	return (
		<section className="grade-student">
			<div className="grade-student__wrapper ">
				<Row>
					<Col xs={12} md={8} lg={8}>
						<div className="group-subjectd-flex d-flex align-items-center position-relative">
							<div className="group-subjectd-item d-flex align-items-center mb-4">
								<p className="group-subject-name">Qrup: </p>
								<Form.Select
									className={`selection ${openSelectGroup ? "active" : ""}`}
									onClick={() => setOpenSelectGroup((prev) => !prev)}
									onChange={handleGroupChange}
									value={selectedGroup || ""}
								>
									{groups?.map((group) => (
										<option key={group.id} value={group.id}>
											{group.name}
										</option>
									))}
								</Form.Select>
							</div>

							<div className="group-subjectd-item d-flex align-items-center mb-4">
								<p className="group-subject-name">Dərs: </p>
								<Form.Select
									className={`selection ${openSelectLesson ? "active" : ""}`}
									onClick={() => setOpenSelectLesson((prev) => !prev)}
									onChange={handleLessonChange}
									value={selectedGroupSubject || ""}
								>
									{Array.isArray(lessons) && lessons.length > 0 ? (
										lessons.map((lesson) =>
											lesson.subject?.translations?.map((translation) => (
												<option key={lesson.id} value={lesson.id}>
													{translation.name}
												</option>
											))
										)
									) : (
										<option value="" disabled>
											Dərs yoxdur
										</option>
									)}
								</Form.Select>
								<div className="group-schedules">
									<OverlayTrigger
										placement="right"
										rootClose
										trigger="click"
										overlay={
											<Popover id="popover-positioned-right">
												<Popover.Body style={{ padding: "5px" }}>
													{renderSchedule(selectedGroup)}
												</Popover.Body>
											</Popover>
										}
									>
										<span className="d-inline-block">
											<button className="operation-btn gray-btn text-white">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
													color="#fff"
													fill="none"
												>
													<circle
														cx="12"
														cy="12"
														r="10"
														stroke="currentColor"
														strokeWidth="1.5"
													/>
													<path
														d="M9.5 9.5L12.9999 12.9996M16 8L11 13"
														stroke="currentColor"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</span>
									</OverlayTrigger>
								</div>
							</div>

							<div className="group-subjectd-item d-flex align-items-center mb-4">
								<p className="group-subject-name">Tarix: </p>
								<button className="week-current" onClick={handleToggleCalendar}>
									{startTime ? `${formattedStartTime} - ${endTime ? formattedEndTime : ''}` : ''}
								</button>


								<div className="date-range-picker">

								</div>
							</div>

							{showCalendar && (
								<div className="calendar-popup">
									<DatePicker
										selected={startTime}
										onChange={dates => { setStartTime(dates[0]); setEndTime(dates[1]); }}
										startDate={startTime}
										endDate={endTime}
										selectsRange
										inline
										dateFormat="dd.MM.yyyy"
										placeholderText="Başlanğıc və Bitiş Tarixi"
									/>
								</div>
							)}

						</div>
						<AverageScore data={getData} />
						{percents && (
							<div className="grade-info">
								<span>Qiymət ortalamasının hesablanması</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.daily_percent))
										? parseInt(percents.daily_percent)
										: percents.daily_percent}% günlük
								</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.quiz_percent))
										? parseInt(percents.quiz_percent)
										: percents.quiz_percent}% quiz
								</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.project_percent))
										? parseInt(percents.project_percent)
										: percents.project_percent}% layihə
								</span>
								<span className="bordered">
									{Number.isInteger(parseFloat(percents.exam_percent))
										? parseInt(percents.exam_percent)
										: percents.exam_percent}% imtahan
								</span>
							</div>
						)}
						<LineChartStudent />
						<div className="charts mt-5 mb-5">
							<Row>
								<Col md={7}>
									<PieChartStudent />
								</Col>
								<Col md={5}>
									<SemicircleProgressBarStudent />
									{/* <SemicircleProgressBarStudent2 /> */}
								</Col>
							</Row>
						</div>
						<GradeTableStudent startTime={formattedStartTime} endTime={formattedEndTime} />
						<NotesTableStudent startTime={formattedStartTime} endTime={formattedEndTime} />
					</Col>

					<TopStudent />
				</Row>



			</div>
		</section>
	)
}

export default GradeStudent
