import { useState } from "react";
import { Col } from "react-bootstrap";
import CreateExercise from "../teachers/CreateExercise";

const TaskCard = ({ data }) => {

	const [showModal, setShowModal] = useState(false);
	const [selectedTask, setSelectedTask] = useState(null);

	const handleOpenModal = (data) => {
		setSelectedTask(data);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedTask(null);
	};

	// if (
	// 	!data ||
	// 	!Array.isArray(data) ||
	// 	data.length === 0
	// ) {
	// 	return <p>Hazırda tapşırığınız yoxdur.</p>;
	// }

	return (
		<>
			<CreateExercise show={showModal} handleClose={handleCloseModal} data={data} />
			<Col key={data.id} md={6} className="mb-4">
				<div className="user__groups-item" onClick={() => handleOpenModal(data)} >
					<p className="user__groups-date">{data?.start_date}</p>
					<h4 className="user__groups-name">{data?.name}</h4>
					<p className="user__groups-code user__group-name">{data?.groups?.name}</p>
				</div>
			</Col>
		</>
	);
};

export default TaskCard;
