import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function DeleteTask({ show, handleClose, data }) {
	const navigate = useNavigate();

	const handleDelete = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(
				`${consts.API_URL}/teacher/task/${data.id}`,
				{
					method: "DELETE",
					headers,
				}
			);
			if (response.ok) {
				handleClose();
				window.location.reload();
			} else {
				handleClose()
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} className="student-modal" backdrop="static">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="alert-modal">
					<div className="alert-image">
						<svg
							width="72"
							height="68"
							viewBox="0 0 72 68"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.1822 26.0832C21.4289 11.4908 25.5523 4.1946 31.2104 2.31644C34.3232 1.28319 37.6762 1.28319 40.789 2.31644C46.4471 4.1946 50.5704 11.4908 58.8172 26.0832C67.0639 40.6756 71.1873 47.9717 69.9503 53.9168C69.2698 57.1874 67.5933 60.1539 65.1611 62.3913C60.7399 66.4582 52.4931 66.4582 35.9997 66.4582C19.5062 66.4582 11.2595 66.4582 6.8383 62.3913C4.40603 60.1539 2.72951 57.1874 2.04902 53.9168C0.812088 47.9717 4.93545 40.6756 13.1822 26.0832Z"
								stroke="#FC5A5A"
								strokeWidth="3"
							/>
							<path
								d="M35.972 47.6665H36.0027"
								stroke="#FC5A5A"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M36 37.4165L36 23.7498"
								stroke="#FC5A5A"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="alert-message">
						<h2 className="alert-title">
							Silmək istədiyinizdən əminsinizmi ?
						</h2>
						<p className="alert-content">
							{data &&
								(data.full_name || data.name) &&
								(data.role === "teacher" || data.role === "student"
									? `${data.full_name} sistemdən silinəcək!`
									: `${data.name} sistemdən silinəcək!`)}
						</p>
						<div className="buttons mt-3">
							<Button type="button" className="no-button" onClick={handleClose}>
								Xeyr
							</Button>
							<Button
								type="button"
								className="yes-button"
								onClick={handleDelete}
							>
								Bəli
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}

export default DeleteTask;
