import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function AddTeacher({ show, handleClose }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "+994",
    birth_day: "",
    specialty: "",
    password: "",
    reset_email: "",
  });

  const [errors, setErrors] = useState({});

  const resetFormData = () => {
    setFormData({
      full_name: "",
      email: "",
      phone: "+994",
      birth_day: "",
      specialty: "",
      reset_email: "",
      password: "",
    });
    setErrors({});
  };

  const formatPhoneNumberCompact = (value) => {
    const cleanValue = value.replace(/\D/g, "");

    if (cleanValue.startsWith("994")) {
      return `+${cleanValue}`;
    } else {
      return `+994${cleanValue.slice(3)}`;
    }
  };

  const cleanPhoneNumber = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value === "") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: "+994 ",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const formattedValue = formatPhoneNumberCompact(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (value.trim() && errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.full_name.trim()) {
      newErrors.full_name = "Ad və soyad daxil edin";
    } else if (formData.full_name.length > 64) {
      newErrors.full_name = "Ad və soyad 64 simvoldan çox olmamalıdır.";
    }

    if (!formData.email) {
      newErrors.email = "İstifadəçi adı daxil edin.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Düzgün istifadəçi adı daxil edin.";
      }
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&.-]{8,}$/;
    if (!formData.password) {
      newErrors.password = "Şifrəni daxil edin.";
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
      "Şifrə minimum 8 simvol, 1 böyük hərf, 1 kiçik hərf, 1 rəqəm və 1 xüsusi simvol(@$!%*?&.-) olmalıdır.";
    }

    if (!formData.phone || formData.phone.length < 13) {
      newErrors.phone = "Telefon nömrəsini daxil edin.";
    }

    if (!formData.birth_day) {
      newErrors.birth_day = "Doğum tarixini daxil edin.";
    }

    if (!formData.specialty) {
      newErrors.specialty = "İxtisası daxil edin.";
    } else if (formData.specialty.length > 64) {
      newErrors.specialty = "İxtisas ən çox 64 simvoldan ibarət olmalıdır.";
    }
    

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    resetFormData();
    handleClose();
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const headers = createHeaders();

      const cleanedPhoneNumber = cleanPhoneNumber(formData.phone);
      const formattedPhoneCompact =
        formatPhoneNumberCompact(cleanedPhoneNumber);

      const dataToSend = {
        ...formData,
        phone: formattedPhoneCompact,
      };

      try {
        const response = await fetch(`${consts.API_URL}/admin/teacher`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(dataToSend),
        });

        const data = await response.json();
        if (response.ok) {
          console.log("Teacher added successfully:", data);
          resetFormData();
          handleClose();
          window.location.reload();
        } else {
          if (data.errors && data.errors.email && data.errors.email.includes("The email has already been taken.")) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              email: "Bu istifadəçi adı artıq istifadə olunur.",
            }));
          } else {
            console.error("Error adding Teacher:", data.message, data.errors);
          }
        }
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleCloseModal} backdrop="static">
      <Modal.Header closeButton>
        <div className="modal-title">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9.48513L8.57372 14L16 5"
              stroke="#696974"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Yeni müəllimin məlumatları</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Ad və Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  placeholder="Ad və Soyad daxil edin"
                  isInvalid={!!errors.full_name}
                  className={errors.full_name ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.full_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>İstifadəçi adı</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="İstifadəçi adı daxil edin"
                  isInvalid={!!errors.email}
                  className={errors.email ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
          <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="reset_email"
                  value={formData.reset_email}
                  onChange={handleChange}
                  placeholder="Email daxil edin"
                  isInvalid={!!errors.reset_email}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput4">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="+994_________"
                  isInvalid={!!errors.phone}
                  className={errors.phone ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formDate" className="select-form">
                <Form.Label>Doğum tarixi</Form.Label>
                <Form.Control
                  type="date"
                  name="birth_day"
                  value={formData.birth_day}
                  onChange={handleChange}
                  isInvalid={!!errors.birth_day}
                  className={`pr-5 ${errors.birth_day ? "input-error" : ""}`}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birth_day}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>Şifrə</Form.Label>
                <Form.Control
                  type="text"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Şifrəni daxil edin"
                  isInvalid={!!errors.password}
                  className={errors.password ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="formInput1">
                <Form.Label>İxtisas</Form.Label>
                <Form.Control
                  type="text"
                  name="specialty"
                  value={formData.specialty}
                  onChange={handleChange}
                  placeholder="İxtisası daxil edin"
                  isInvalid={!!errors.specialty}
                  className={errors.specialty ? "input-error" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.specialty}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-btn btn-green" onClick={handleSubmit}>
          Müəllimi əlavə et
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTeacher;
