import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import TopStudents from "../components/TopStudent";
import MenuGrid from "../components/MenuGrid";
import UserInfo from "../components/admin/UserInfo";
import ContactCard from "../components/admin/ContactCard";

function MainPageAdmin() {
	const [openSelectTwo, setOpenSelectTwo] = useState(false);

	const mockMenuData = [
		{
			path: "/groups-admin",
			name: "Qruplar",
			background: "linear-gradient(180deg, #2d81ee 0%, #44c2fd 100%)",
			svg: `
        <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.202087 7.7499C0.202087 3.88046 3.33889 0.743652 7.20834 0.743652H12.125C15.9945 0.743652 19.1313 3.88046 19.1313 7.7499V12.6666C19.1313 16.536 15.9944 19.6728 12.125 19.6728H7.20834C3.33889 19.6728 0.202087 16.536 0.202087 12.6666V7.7499ZM7.20834 4.92282C5.64698 4.92282 4.38125 6.18855 4.38125 7.7499V12.6666C4.38125 14.2279 5.64698 15.4937 7.20834 15.4937H12.125C13.6864 15.4937 14.9521 14.2279 14.9521 12.6666V7.7499C14.9521 6.18855 13.6864 4.92282 12.125 4.92282H7.20834Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.202087 29.8749C0.202087 26.0055 3.33889 22.8687 7.20834 22.8687H12.125C15.9945 22.8687 19.1313 26.0055 19.1313 29.8749V34.7916C19.1313 38.661 15.9944 41.7978 12.125 41.7978H7.20834C3.33889 41.7978 0.202087 38.661 0.202087 34.7916V29.8749ZM7.20834 27.0478C5.64698 27.0478 4.38125 28.3135 4.38125 29.8749V34.7916C4.38125 36.3529 5.64698 37.6187 7.20834 37.6187H12.125C13.6864 37.6187 14.9521 36.3529 14.9521 34.7916V29.8749C14.9521 28.3135 13.6864 27.0478 12.125 27.0478H7.20834Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.3271 7.7499C22.3271 3.88046 25.4639 0.743652 29.3333 0.743652H34.25C38.1194 0.743652 41.2563 3.88046 41.2563 7.7499V12.6666C41.2563 16.536 38.1194 19.6728 34.25 19.6728H29.3333C25.4639 19.6728 22.3271 16.536 22.3271 12.6666V7.7499ZM29.3333 4.92282C27.772 4.92282 26.5063 6.18855 26.5063 7.7499V12.6666C26.5063 14.2279 27.772 15.4937 29.3333 15.4937H34.25C35.8114 15.4937 37.0771 14.2279 37.0771 12.6666V7.7499C37.0771 6.18855 35.8114 4.92282 34.25 4.92282H29.3333Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.3271 29.8749C22.3271 26.0055 25.4639 22.8687 29.3333 22.8687H34.25C38.1194 22.8687 41.2563 26.0055 41.2563 29.8749V34.7916C41.2563 38.661 38.1194 41.7978 34.25 41.7978H29.3333C25.4639 41.7978 22.3271 38.661 22.3271 34.7916V29.8749ZM29.3333 27.0478C27.772 27.0478 26.5063 28.3135 26.5063 29.8749V34.7916C26.5063 36.3529 27.772 37.6187 29.3333 37.6187H34.25C35.8114 37.6187 37.0771 36.3529 37.0771 34.7916V29.8749C37.0771 28.3135 35.8114 27.0478 34.25 27.0478H29.3333Z"
              fill="white"
            />
          </svg>
      `,
		},
		// 		{
		// 			path: "/lessons",
		// 			name: "Dərslər",
		// 			background: "linear-gradient(180deg, #BDFF7C 0%, #8FE33A 100%)",
		// 			svg: `
		//      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		// <path d="M7.2959 37.5001V14.5834C7.2959 6.25008 9.37923 4.16675 17.7126 4.16675H32.2959C40.6292 4.16675 42.7126 6.25008 42.7126 14.5834V35.4167C42.7126 35.7084 42.7126 36.0001 42.6917 36.2917" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
		// <path d="M13.2334 31.25H42.7126V38.5417C42.7126 42.5625 39.4417 45.8333 35.4209 45.8333H14.5876C10.5667 45.8333 7.2959 42.5625 7.2959 38.5417V37.1875C7.2959 33.9167 9.96256 31.25 13.2334 31.25Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
		// <path d="M16.6709 14.5833H33.3376" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
		// <path d="M16.6709 21.875H27.0876" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
		// </svg>
		//       `,
		// 		},
		{
			path: "/teachers",
			name: "Müəllimlər",
			background: "linear-gradient(180deg, #4C8463 0%, #40B88F 100%)",
			svg: `
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28 31.5V38.5" stroke="white" strokeWidth="3.5" strokeLinecap="round"/>
      <path d="M21 33.8333V36.1666" stroke="white" strokeWidth="3.5" strokeLinecap="round"/>
      <path d="M35 33.8333V36.1666" stroke="white" strokeWidth="3.5" strokeLinecap="round"/>
      <path d="M49 36.1036V13.1928C49 11.4987 46.9233 10.697 45.7967 11.9562L38.8052 19.7711C37.9922 20.68 36.5417 20.5585 35.8892 19.527L30.2146 10.5562C29.1831 8.92558 26.8169 8.92559 25.7854 10.5563L20.1108 19.527C19.4583 20.5585 18.0078 20.68 17.1948 19.7711L10.2033 11.9562C9.07674 10.697 7 11.4987 7 13.1928V36.1036C7 41.9374 11.701 46.6666 17.5 46.6666H38.5C44.299 46.6666 49 41.9374 49 36.1036Z" stroke="white" strokeWidth="4" strokeLinecap="round"/>
      </svg>
      `,
		},
		{
			path: "/students",
			name: "Tələbələr",
			background: "linear-gradient(180deg, #301551 0%, #6643A2 100%)",
			svg: `
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.9655 38.7431C14.3289 38.7431 12.7344 39.2616 11.4108 40.2242L10.9985 40.5241C8.78421 42.1345 9.92334 45.6375 12.6613 45.6375H31.1457C33.8837 45.6375 35.0228 42.1345 32.8086 40.5241L32.3962 40.2242C31.0727 39.2616 29.4782 38.7431 27.8416 38.7431H15.9655ZM8.95275 36.8444C10.9906 35.3623 13.4457 34.564 15.9655 34.564H27.8416C30.3614 34.564 32.8164 35.3623 34.8543 36.8444L35.2666 37.1443C40.7542 41.1352 37.9311 49.8167 31.1457 49.8167H12.6613C5.87591 49.8167 3.05283 41.1352 8.54044 37.1443L8.95275 36.8444Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9035 14.3813C18.165 14.3813 15.1343 17.412 15.1343 21.1506C15.1343 24.8892 18.165 27.9199 21.9035 27.9199C25.6421 27.9199 28.6728 24.8892 28.6728 21.1506C28.6728 17.412 25.6421 14.3813 21.9035 14.3813ZM10.9551 21.1506C10.9551 15.1039 15.8569 10.2021 21.9035 10.2021C27.9502 10.2021 32.852 15.1039 32.852 21.1506C32.852 27.1972 27.9502 32.099 21.9035 32.099C15.8569 32.099 10.9551 27.1972 10.9551 21.1506Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.1182 28.9927C39.0949 28.9927 38.0979 29.3169 37.2703 29.9188C35.9518 30.8777 36.6301 32.9637 38.2605 32.9637H49.2872C50.9176 32.9637 51.5959 30.8777 50.2774 29.9188C49.4498 29.3169 48.4528 28.9927 47.4295 28.9927H40.1182ZM35.5352 27.533C36.867 26.5645 38.4714 26.0427 40.1182 26.0427H47.4295C49.0763 26.0427 50.6807 26.5645 52.0125 27.533C55.6416 30.1724 53.7746 35.9137 49.2872 35.9137H38.2605C33.7731 35.9137 31.9061 30.1724 35.5352 27.533Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7738 13.7667C41.5306 13.7667 39.7121 15.5852 39.7121 17.8284C39.7121 20.0717 41.5306 21.8902 43.7738 21.8902C46.0171 21.8902 47.8356 20.0717 47.8356 17.8284C47.8356 15.5852 46.0171 13.7667 43.7738 13.7667ZM36.7621 17.8284C36.7621 13.9559 39.9013 10.8167 43.7738 10.8167C47.6463 10.8167 50.7856 13.9559 50.7856 17.8284C50.7856 21.7009 47.6463 24.8402 43.7738 24.8402C39.9013 24.8402 36.7621 21.7009 36.7621 17.8284Z" fill="white"/>
        </svg>
      `,
		},
		{
			path: "/graduates",
			name: "Məzunlar",
			background: "linear-gradient(180deg, #C46B00 0%, #ED8A0A 100%)",
			svg: `
     <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_342_6786)">
    <path d="M1.6875 20.25L27 6.75L52.3125 20.25L27 33.75L1.6875 20.25Z" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M39.6562 50.625V27L27 20.25" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M46.4062 23.3931V34.9103C46.4047 35.2687 46.2862 35.6168 46.0687 35.9017C44.6555 37.8001 38.6648 44.7188 27 44.7188C15.3352 44.7188 9.34453 37.8001 7.93125 35.9017C7.71377 35.6168 7.59526 35.2687 7.59375 34.9103V23.3931" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_342_6786">
    <rect width="54" height="54" fill="white"/>
    </clipPath>
    </defs>
    </svg>
      `,
		},
		{
			path: "/waiting-projects",
			name: "Layihə gözləyənlər",
			background: "linear-gradient(0deg, #E74645 0%, #DC1E44 100%)",
			svg: `
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M31.5 12.7313C21.1343 12.7313 12.7312 21.1344 12.7312 31.5C12.7312 41.8657 21.1343 50.2688 31.5 50.2688C41.8657 50.2688 50.2687 41.8657 50.2687 31.5C50.2687 21.1344 41.8657 12.7313 31.5 12.7313ZM8.26874 31.5C8.26874 18.6698 18.6697 8.2688 31.5 8.2688C44.3303 8.2688 54.7312 18.6698 54.7312 31.5C54.7312 44.3303 44.3303 54.7313 31.5 54.7313C18.6697 54.7313 8.26874 44.3303 8.26874 31.5Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.25 24.0188C27.4823 24.0188 28.4812 25.0178 28.4812 26.25L28.4812 36.75C28.4812 37.9823 27.4823 38.9813 26.25 38.9813C25.0177 38.9813 24.0187 37.9823 24.0187 36.75L24.0187 26.25C24.0187 25.0178 25.0177 24.0188 26.25 24.0188Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.75 24.0188C37.9823 24.0188 38.9812 25.0178 38.9812 26.25L38.9812 36.75C38.9812 37.9823 37.9823 38.9813 36.75 38.9813C35.5177 38.9813 34.5187 37.9823 34.5187 36.75L34.5187 26.25C34.5187 25.0178 35.5177 24.0188 36.75 24.0188Z" fill="white"/>
        </svg>
      `,
		},
		{
			path: "/requests",
			name: "Gələn müraciətlər",
			background: "linear-gradient(180deg, #1B1FBE 0%, #008EE6 100%)",
			svg: `
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M31.8499 24.5701C29.7437 25.6232 27.2647 25.6232 25.1585 24.5701L17.532 20.7569C16.5348 20.2583 15.3222 20.6625 14.8236 21.6597C14.325 22.6569 14.7292 23.8695 15.7264 24.3682L23.3529 28.1814C26.5957 29.8028 30.4127 29.8028 33.6555 28.1814L41.282 24.3682C42.2792 23.8695 42.6834 22.6569 42.1848 21.6597C41.6862 20.6625 40.4736 20.2583 39.4764 20.7569L31.8499 24.5701Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.11046 21.375C5.11046 15.0133 10.2676 9.8562 16.6292 9.8562H40.3792C46.7408 9.8562 51.898 15.0133 51.898 21.375V34.4375C51.898 40.7991 46.7408 45.9562 40.3792 45.9562H16.6292C10.2676 45.9562 5.11046 40.7991 5.11046 34.4375V21.375ZM16.6292 13.8937C12.4974 13.8937 9.14796 17.2432 9.14796 21.375V34.4375C9.14796 38.5692 12.4974 41.9187 16.6292 41.9187H40.3792C44.511 41.9187 47.8605 38.5692 47.8605 34.4375V21.375C47.8605 17.2432 44.511 13.8937 40.3792 13.8937H16.6292Z" fill="white"/>
        </svg>

      `,
		},
		{
			path: "/teachers-activity",
			name: "Müəllimin aktivliyi",
			background: "linear-gradient(180deg, #711010 0%, #D4425F 100%)",
			svg: `
         <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.9752 31.7039C50.0742 31.7039 50.9745 32.5967 50.8737 33.691C50.5437 37.2757 49.3244 40.7331 47.3128 43.7437C44.9315 47.3075 41.547 50.0852 37.5871 51.7254C33.6272 53.3656 29.2699 53.7948 25.0661 52.9586C20.8624 52.1224 17.001 50.0584 13.9702 47.0277C10.9395 43.9969 8.87549 40.1355 8.03931 35.9318C7.20312 31.728 7.63228 27.3707 9.27251 23.4108C10.9127 19.4509 13.6904 16.0664 17.2542 13.6851C20.2648 11.6735 23.7222 10.4542 27.307 10.1242C28.4012 10.0234 29.294 10.9237 29.294 12.0227C29.294 13.1216 28.4003 14.0009 27.3083 14.1242C24.5119 14.44 21.8209 15.4199 19.4651 16.994C16.5557 18.938 14.2881 21.701 12.9491 24.9337C11.6101 28.1664 11.2597 31.7236 11.9424 35.1554C12.625 38.5872 14.31 41.7395 16.7842 44.2137C19.2584 46.688 22.4107 48.3729 25.8425 49.0555C29.2743 49.7382 32.8315 49.3878 36.0642 48.0488C39.2969 46.7098 42.06 44.4422 44.0039 41.5328C45.578 39.177 46.5579 36.486 46.8737 33.6896C46.9971 32.5976 47.8763 31.7039 48.9752 31.7039Z" fill="white"/>
        <path d="M32.9419 12.7548C33.1518 9.95522 35.6263 7.79918 38.3004 8.65405C41.5358 9.68834 44.5063 11.4764 46.953 13.9001C49.3998 16.3239 51.2158 19.2774 52.2806 22.5028C53.1607 25.1687 51.0282 27.6635 48.2307 27.8998L37.6447 28.7941C34.527 29.0575 31.9138 26.4688 32.1477 23.3488L32.9419 12.7548Z" fill="white"/>
        </svg>
      `,
		},
		// {
		// 	path: "/groups-activity",
		// 	name: "Qrupun aktivliyi",
		// 	background: "linear-gradient(180deg, #711010 0%, #D4425F 100%)",
		// 	svg: `
		//    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
		//   <path d="M48.9752 31.7039C50.0742 31.7039 50.9745 32.5967 50.8737 33.691C50.5437 37.2757 49.3244 40.7331 47.3128 43.7437C44.9315 47.3075 41.547 50.0852 37.5871 51.7254C33.6272 53.3656 29.2699 53.7948 25.0661 52.9586C20.8624 52.1224 17.001 50.0584 13.9702 47.0277C10.9395 43.9969 8.87549 40.1355 8.03931 35.9318C7.20312 31.728 7.63228 27.3707 9.27251 23.4108C10.9127 19.4509 13.6904 16.0664 17.2542 13.6851C20.2648 11.6735 23.7222 10.4542 27.307 10.1242C28.4012 10.0234 29.294 10.9237 29.294 12.0227C29.294 13.1216 28.4003 14.0009 27.3083 14.1242C24.5119 14.44 21.8209 15.4199 19.4651 16.994C16.5557 18.938 14.2881 21.701 12.9491 24.9337C11.6101 28.1664 11.2597 31.7236 11.9424 35.1554C12.625 38.5872 14.31 41.7395 16.7842 44.2137C19.2584 46.688 22.4107 48.3729 25.8425 49.0555C29.2743 49.7382 32.8315 49.3878 36.0642 48.0488C39.2969 46.7098 42.06 44.4422 44.0039 41.5328C45.578 39.177 46.5579 36.486 46.8737 33.6896C46.9971 32.5976 47.8763 31.7039 48.9752 31.7039Z" fill="white"/>
		//   <path d="M32.9419 12.7548C33.1518 9.95522 35.6263 7.79918 38.3004 8.65405C41.5358 9.68834 44.5063 11.4764 46.953 13.9001C49.3998 16.3239 51.2158 19.2774 52.2806 22.5028C53.1607 25.1687 51.0282 27.6635 48.2307 27.8998L37.6447 28.7941C34.527 29.0575 31.9138 26.4688 32.1477 23.3488L32.9419 12.7548Z" fill="white"/>
		//   </svg>
		// `,
		// },
		{
			path: "/archived-groups",
			name: "Arxivləşdirilmiş qruplar",
			background: "linear-gradient(180deg, #2d81ee 0%, #44c2fd 100%)",
			svg: `
        <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.202087 7.7499C0.202087 3.88046 3.33889 0.743652 7.20834 0.743652H12.125C15.9945 0.743652 19.1313 3.88046 19.1313 7.7499V12.6666C19.1313 16.536 15.9944 19.6728 12.125 19.6728H7.20834C3.33889 19.6728 0.202087 16.536 0.202087 12.6666V7.7499ZM7.20834 4.92282C5.64698 4.92282 4.38125 6.18855 4.38125 7.7499V12.6666C4.38125 14.2279 5.64698 15.4937 7.20834 15.4937H12.125C13.6864 15.4937 14.9521 14.2279 14.9521 12.6666V7.7499C14.9521 6.18855 13.6864 4.92282 12.125 4.92282H7.20834Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.202087 29.8749C0.202087 26.0055 3.33889 22.8687 7.20834 22.8687H12.125C15.9945 22.8687 19.1313 26.0055 19.1313 29.8749V34.7916C19.1313 38.661 15.9944 41.7978 12.125 41.7978H7.20834C3.33889 41.7978 0.202087 38.661 0.202087 34.7916V29.8749ZM7.20834 27.0478C5.64698 27.0478 4.38125 28.3135 4.38125 29.8749V34.7916C4.38125 36.3529 5.64698 37.6187 7.20834 37.6187H12.125C13.6864 37.6187 14.9521 36.3529 14.9521 34.7916V29.8749C14.9521 28.3135 13.6864 27.0478 12.125 27.0478H7.20834Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.3271 7.7499C22.3271 3.88046 25.4639 0.743652 29.3333 0.743652H34.25C38.1194 0.743652 41.2563 3.88046 41.2563 7.7499V12.6666C41.2563 16.536 38.1194 19.6728 34.25 19.6728H29.3333C25.4639 19.6728 22.3271 16.536 22.3271 12.6666V7.7499ZM29.3333 4.92282C27.772 4.92282 26.5063 6.18855 26.5063 7.7499V12.6666C26.5063 14.2279 27.772 15.4937 29.3333 15.4937H34.25C35.8114 15.4937 37.0771 14.2279 37.0771 12.6666V7.7499C37.0771 6.18855 35.8114 4.92282 34.25 4.92282H29.3333Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.3271 29.8749C22.3271 26.0055 25.4639 22.8687 29.3333 22.8687H34.25C38.1194 22.8687 41.2563 26.0055 41.2563 29.8749V34.7916C41.2563 38.661 38.1194 41.7978 34.25 41.7978H29.3333C25.4639 41.7978 22.3271 38.661 22.3271 34.7916V29.8749ZM29.3333 27.0478C27.772 27.0478 26.5063 28.3135 26.5063 29.8749V34.7916C26.5063 36.3529 27.772 37.6187 29.3333 37.6187H34.25C35.8114 37.6187 37.0771 36.3529 37.0771 34.7916V29.8749C37.0771 28.3135 35.8114 27.0478 34.25 27.0478H29.3333Z"
              fill="white"
            />
          </svg>
      `,
		},
		{
			path: "/deleted",
			name: "Silinmişlər",
			background: "linear-gradient(180deg, #671F6E 0%, #D527B7 100%)",
			svg: `
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7316 20.4725C13.1047 16.1737 16.4378 12.3193 20.7821 12.3193H40.3291C44.6734 12.3193 48.0064 16.1737 47.3795 20.4725L43.6526 46.0281C43.1421 49.5289 40.1401 52.1249 36.6022 52.1249H24.5089C20.971 52.1249 17.969 49.5289 17.4585 46.0281L13.7316 20.4725ZM20.7821 16.5693C19.0291 16.5693 17.6842 18.1246 17.9372 19.8592L21.664 45.4148C21.87 46.8274 23.0813 47.8749 24.5089 47.8749H36.6022C38.0298 47.8749 39.2411 46.8274 39.4471 45.4148L43.174 19.8592C43.4269 18.1246 42.082 16.5693 40.3291 16.5693H20.7821Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.9861 7.5C23.9861 6.32639 24.9375 5.375 26.1111 5.375H35C36.1736 5.375 37.125 6.32639 37.125 7.5C37.125 8.67361 36.1736 9.625 35 9.625H26.1111C24.9375 9.625 23.9861 8.67361 23.9861 7.5Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.6768 26.2929C24.4969 26.1758 25.2567 26.7456 25.3738 27.5657L27.596 43.1213C27.7132 43.9414 27.1433 44.7012 26.3232 44.8183C25.5031 44.9355 24.7433 44.3657 24.6262 43.5456L22.404 27.99C22.2868 27.1699 22.8567 26.4101 23.6768 26.2929Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.7879 44.8183C33.9678 44.7012 33.3979 43.9414 33.5151 43.1213L35.7373 27.5657C35.8545 26.7456 36.6143 26.1758 37.4344 26.2929C38.2545 26.4101 38.8243 27.1699 38.7071 27.99L36.4849 43.5455C36.3678 44.3656 35.608 44.9355 34.7879 44.8183Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.5556 44.8333C29.7271 44.8333 29.0556 44.1617 29.0556 43.3333L29.0556 27.7777C29.0556 26.9493 29.7271 26.2777 30.5556 26.2777C31.384 26.2777 32.0556 26.9493 32.0556 27.7777V43.3333C32.0556 44.1617 31.384 44.8333 30.5556 44.8333Z" fill="white"/>
        </svg>
      `,
		},
		{
			path: "/teaching-directions",
			name: "Fənnlər",
			background: "linear-gradient(180deg, #583AFF 0%, #9F6BFF 100%)",
			svg: `
       <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.8438 16.0417C27.8438 14.9659 28.7159 14.0938 29.7917 14.0938H48.125C49.2008 14.0938 50.0729 14.9659 50.0729 16.0417C50.0729 17.1175 49.2008 17.9896 48.125 17.9896H29.7917C28.7159 17.9896 27.8438 17.1175 27.8438 16.0417Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.92709 36.6667C4.92709 35.5909 5.7992 34.7188 6.875 34.7188H25.2083C26.2841 34.7188 27.1563 35.5909 27.1563 36.6667C27.1563 37.7425 26.2841 38.6146 25.2083 38.6146H6.875C5.7992 38.6146 4.92709 37.7425 4.92709 36.6667Z" fill="white"/>
      <path d="M20.625 16.0417C20.625 19.8387 17.547 22.9167 13.75 22.9167C9.95304 22.9167 6.875 19.8387 6.875 16.0417C6.875 12.2448 9.95304 9.16675 13.75 9.16675C17.547 9.16675 20.625 12.2448 20.625 16.0417Z" fill="white"/>
      <path d="M48.125 36.6667C48.125 40.4637 45.047 43.5417 41.25 43.5417C37.453 43.5417 34.375 40.4637 34.375 36.6667C34.375 32.8698 37.453 29.7917 41.25 29.7917C45.047 29.7917 48.125 32.8698 48.125 36.6667Z" fill="white"/>
      </svg>
      `,
		},
		{
			path: "/templates",
			name: "Şablonlar",
			background: "linear-gradient(rgb(255 58 179) 0%, rgb(159, 107, 255) 100%)",
			svg: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="55" height="55" color="#ffffff" fill="none">
    <path d="M3 11C3 7.25027 3 5.3754 3.95491 4.06107C4.26331 3.6366 4.6366 3.26331 5.06107 2.95491C6.3754 2 8.25027 2 12 2C15.7497 2 17.6246 2 18.9389 2.95491C19.3634 3.26331 19.7367 3.6366 20.0451 4.06107C21 5.3754 21 7.25027 21 11V13C21 16.7497 21 18.6246 20.0451 19.9389C19.7367 20.3634 19.3634 20.7367 18.9389 21.0451C17.6246 22 15.7497 22 12 22C8.25027 22 6.3754 22 5.06107 21.0451C4.6366 20.7367 4.26331 20.3634 3.95491 19.9389C3 18.6246 3 16.7497 3 13V11Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 9.5L8 9.5M13.5 14.5H10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>


      `,
		},
		{
			path: "/course-schedule",
			name: "Dərs cədvəli",
			background: "linear-gradient(180deg, #FF8A6B 0%, #FF633A 100%)",
			svg: `<svg
								width="50"
								height="50"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9 14.5H13M9 9.5H17"
									stroke="#fff"
									strokeWidth="2"
									strokeLinecap="round"
								/>
								<path
									d="M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2H13.5C17.2712 2 19.1569 2 20.3284 3.17157C21.5 4.34315 21.5 6.22876 21.5 10V14C21.5 17.7712 21.5 19.6569 20.3284 20.8284C19.1569 22 17.2712 22 13.5 22H12C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10Z"
									stroke="#fff"
									strokeWidth="2"
								/>
								<path
									d="M5 6L2.5 6M5 12L2.5 12M5 18H2.5"
									stroke="#fff"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
      `,
		},
		{
			path: "/about-us",
			name: "Haqqımızda",
			background: "linear-gradient(rgb(58 255 234) 0%, rgb(38 138 187) 100%)",
			svg: `
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" width="55" height="55" color="#ffffff" fill="none">
  <g transform="scale(2)">
    <path d="M18 15V9C18 6.17157 18 4.75736 17.1213 3.87868C16.2426 3 14.8284 3 12 3H8C5.17157 3 3.75736 3 2.87868 3.87868C2 4.75736 2 6.17157 2 9V15C2 17.8284 2 19.2426 2.87868 20.1213C3.75736 21 5.17157 21 8 21H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 8L14 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 12L14 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 16L10 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 8H19C20.4142 8 21.1213 8 21.5607 8.43934C22 8.87868 22 9.58579 22 11V19C22 20.1046 21.1046 21 20 21C18.8954 21 18 20.1046 18 19V8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>
      `,
		},
	];

	return (
		<>
			<section className="admin">
				<div className="user__wrapper">
					<Row>
						<Col xs={12} md={8} lg={8}>
							<div className="user__left">
								<UserInfo />
								<div className="user__bottom mb-5">
									<Row>
										{mockMenuData.map((item, index) => (
											<MenuGrid
												key={index}
												name={item.name}
												background={item.background}
												path={item.path}
												image={item.svg}
											/>
										))}
									</Row>
								</div>
							</div>
						</Col>
						<TopStudents />
					</Row>
				</div>
			</section>
		</>
	);
}

export default MainPageAdmin;
