import { SET_SELECTED_GROUP, SET_DATA_STATUS, SET_GET_DATA, SET_GROUP_NAME, SET_SELECTED_GROUP_SUBJECT, SET_SELECTED_SUBJECT } from './action';

const initialState = {
	// selectedGroup: null,
	// selectedGroupSubject: null,
	// selectedGroup: localStorage.getItem("selectedGroup") || null,
	// selectedGroupSubject: localStorage.getItem("selectedGroupSubject") || null,
	// selectedSubject: localStorage.getItem("selectedSubject") || null,
	selectedGroup: JSON.parse(localStorage.getItem("selectedGroup")) || null,
	selectedGroupSubject: JSON.parse(localStorage.getItem("selectedGroupSubject")) || null,
	selectedSubject: JSON.parse(localStorage.getItem("selectedSubject")) || null,
	dataStatus: 0,
	getData: 0,
	groupName: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_GROUP:
			return {
				...state,
				selectedGroup: action.payload,
			};
		case SET_SELECTED_GROUP_SUBJECT:
			return {
				...state,
				selectedGroupSubject: action.payload,
			};
		case SET_SELECTED_SUBJECT:
			return {
				...state,
				selectedSubject: action.payload,
			};
		case SET_DATA_STATUS:
			return {
				...state,
				dataStatus: action.payload,
			};
		case SET_GET_DATA:
			return {
				...state,
				getData: action.payload,
			};
		case SET_GROUP_NAME:
			return {
				...state,
				groupName: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
