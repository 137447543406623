import React from "react";
import EditGradeTable from "../components/teachers/EditGradeTable";

function EditGradeTeacher({role}) {
  return (
    <>
      <section className="add-grade">
        <EditGradeTable role={role} />
      </section>
    </>
  );
}

export default EditGradeTeacher;
