import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const formatGrades = (grades) => {
	const defaultGrades = {
		monday: "",
		tuesday: "",
		wednesday: "",
		thursday: "",
		friday: "",
		saturday: "",
		sunday: "",
	};

	if (!grades) {
		return defaultGrades;
	}

	const formattedGrades = { ...defaultGrades };

	grades.forEach(({ week_day, grade, attendance }) => {
		if (formattedGrades.hasOwnProperty(week_day)) {
			formattedGrades[week_day] = attendance === 0 ? "Q" : attendance === 1 && !grade ? "+" : (grade !== undefined && grade !== null ? grade : "") || "";
		}
	});

	return formattedGrades;
};

function GradeTableItem({ data, grades }) {
	const formattedGrades = formatGrades(grades);

	return (
		<tr>
			<td>
				<div
					className="column-content"
					style={{
						paddingLeft: "20px",
						marginLeft: "20px",
						borderTopLeftRadius: "8px",
						borderBottomLeftRadius: "8px",
					}}
				>
					{data.full_name}
				</div>
			</td>
			<td>
				<div className="column-content">
					<div className="table-icon">
						{data.email ? (
							<OverlayTrigger
								placement="right"
								className="hover-tooltip"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="button-tooltip" className="tooltip-wrapper">
										{data.email}
									</Tooltip>
								}
							>
								<img src="/img/email.png" alt="Email icon" />
							</OverlayTrigger>
						) : (
							<span>Email yoxdur</span>
						)}
					</div>
				</div>
			</td>
			<td>
				<div
					className="column-content"
					style={{
						marginRight: "20px",
						borderTopRightRadius: "8px",
						borderBottomRightRadius: "8px",
					}}
				>
					<div className="table-icon">
						{data.phone ? (
							<OverlayTrigger
								placement="right"
								className="hover-tooltip"
								delay={{ show: 250, hide: 400 }}
								overlay={
									<Tooltip id="button-tooltip" className="tooltip-wrapper">
										{data.phone}
									</Tooltip>
								}
							>
								<img src="/img/phone.png" alt="Phone icon" />
							</OverlayTrigger>
						) : (
							<span>Telefon nömrəsi yoxdur</span>
						)}
					</div>
				</div>
			</td>
			{[
				"monday",
				"tuesday",
				"wednesday",
				"thursday",
				"friday",
				"saturday",
				"sunday",
			].map((day) => (
				<td key={day}>
					<div className="column-content bg-white grade">
						<input
							type="text"
							value={formattedGrades[day]}
							disabled
							className={`grade-input ${formattedGrades[day] === "Q" ? "q" : formattedGrades[day] === "+" ? "plus" : "grade-btn"
								}`}
						//   className="grade-input"
						//   style={{
						//     backgroundColor:
						//       formattedGrades[day] === "Q" ? "#fc5a5a" : "#b3b3b3",
						//     color: formattedGrades[day] === "Q" ? "#dfe1f3" : "#101110",
						//   }}
						/>
					</div>
				</td>
			))}

			<td>
				<div className="column-content bg-white average">
					<input
						type="number"
						value={data?.total_average?.all_average ? parseFloat(data.total_average.all_average).toFixed(2) : ''}
						disabled
						className="grade-input"
					/>
				</div>
			</td>
			<td>
				<div className=" right column-content bg-white average">
					<input
						type="number"
						value={data?.total_average?.week_average ? parseFloat(data.total_average.week_average).toFixed(2) : ''}
						disabled
						className="grade-input"
					/>
				</div>
			</td>
		</tr>
	);
}

export default GradeTableItem;
