import React, { useState } from "react";
import GroupsTable from "../components/admin/GroupsTable";
import LessonsTable from "../components/admin/LessonsTable";

function GroupsAdmin() {
	const [currentTable, setCurrentTable] = useState("groups");
	const [selectedGroupId, setSelectedGroupId] = useState(null);

	const handleTableSwitch = (tableName, groupId = null) => {
		setCurrentTable(tableName);
		if (groupId) setSelectedGroupId(groupId); 
	};

	return (
		<section className="group-admin lessons">
			{currentTable === "groups" ? (
				<>
					<h3 className="title mb-3">Qruplar</h3>
					<GroupsTable onSwitchTable={handleTableSwitch} />
				</>
			) : (
				<>
					<h3 className="title mb-3">Dərslər</h3>
					<LessonsTable onSwitchTable={handleTableSwitch} groupId={selectedGroupId} />
					<button className="lessons-back"
						onClick={() => handleTableSwitch("groups")}
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.825 9L9.425 14.6L8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825Z" fill="#92929D" />
						</svg>
					</button>
				</>
			)}
		</section>
	);
}

export default GroupsAdmin;
