import React from "react";

function NotesTableItemStudent({ data }) {
	return (
		<>
			{data && data.grades && data.grades.length > 0 ? (
				data.grades.map((gradeItem, index) =>
					gradeItem.note ? (
						<tr key={index}>
							<td>
								<div className="left column-content">{gradeItem.date}</div>
							</td>
							<td>
								<div className="column-content" style={{ overflow: "hidden" }}>
									<p
										className="column-note"
										style={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											width: "100%",
											maxWidth: "400px",
										}}
										title={gradeItem.note}
									>
										{gradeItem.note}
									</p>
								</div>
							</td>
						</tr>
					) : null
				)
			) : (
				<tr>
					<td colSpan={4} className="empty-table">
						Seçdiyiniz günlər üzrə qeydlər yoxdur
					</td>
				</tr>
			)}
		</>
	);
}

export default NotesTableItemStudent;
