import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as consts from "../../consts/Consts";
import {
	fetchSubjects,
	fetchTeachers,
	fetchGroups,
	createHeaders,
} from "../../functions/apiService";
import CustomSelect from '../CustomSelect'
import CustomDropdown from '../CustomDropdown'

const weekDaysMapping = {
	"Bazar ertəsi": "monday",
	"Çərşənbə axşamı": "tuesday",
	"Çərşənbə": "wednesday",
	"Cümə axşamı": "thursday",
	"Cümə": "friday",
	"Şənbə": "saturday",
	"Bazar": "sunday",
};

const AddLesson = ({ show, handleClose, id, data, selectedGroup, groupId }) => {
	const navigate = useNavigate();
	const [perPage, setPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertType, setAlertType] = useState("success");
	const [formData, setFormData] = useState({
		group_id: groupId ? groupId : "",
		subject_id: "",
		teacher_id: "",
		descriptions: "",
		// online_lesson_link: "",
		lesson_schedules: [
			{
				week_day: "monday",
				start_time: "",
				end_time: ""
			},
		],
	});

	useEffect(() => {
		if (selectedGroup) {
			setFormData((prev) => ({ ...prev, group_id: selectedGroup.id }));
		}
	}, [selectedGroup]);

	const [errors, setErrors] = useState({});

	const resetFormData = () => {
		setFormData({
			group_id: "",
			subject_id: "",
			teacher_id: "",
			descriptions: "",
			// online_lesson_link: "",
			lesson_schedules: [
				{
					week_day: "monday",
					start_time: "",
					end_time: ""
				},
			],
			week_day: [],
			start_time: {},
			end_time: {}
		});
		setErrors({});
	};

	const [isAdditionalVisible, setIsAdditionalVisible] = useState(false);

	// const toggleAdditional = () => {
	// 	setIsAdditionalVisible((prev) => !prev);
	// };
	const addMoreSchedule = (day = "Bazar ertəsi") => {
		const englishDay = weekDaysMapping[day] || "monday";
		setFormData((prev) => ({
			...prev,
			lesson_schedules: [
				...(prev.lesson_schedules || []),
				{ week_day: englishDay, start_time: "", end_time: "" },
			],
		}));
	};



	const handleScheduleChange = (e, field, index) => {
		const { value } = e.target;
		setFormData((prev) => {
			if (!prev.lesson_schedules || !prev.lesson_schedules[index]) {
				return prev;
			}
			const updatedSchedules = [...prev.lesson_schedules];
			updatedSchedules[index][field] = value;
			return { ...prev, lesson_schedules: updatedSchedules };
		});
	};

	const removeSchedule = (index) => {
		setFormData((prev) => ({
			...prev,
			lesson_schedules: prev.lesson_schedules.filter((_, i) => i !== index),
		}));
	};

	const handleChange = (e) => {
		const { name, value } = e.target || { name: e.name, value: e.value };

		// if (name === "name" && value.includes('-')) {
		// 	return;
		// }

		setFormData((prevData) => {
			let updatedName = prevData.name;

			// if (name === "subject_id") {
			// 	const selectedSubject = getData.find(subject => subject.id === value);
			// 	const subjectName = selectedSubject?.translations[0]?.name || "";

			// 	const nameParts = prevData.name.split(' - ');
			// 	const manualName = nameParts[0] || "";

			// 	updatedName = `${manualName} - ${subjectName}`.trim();
			// }

			if (name === "name") {
				updatedName = value;
			}

			return {
				...prevData,
				[name]: value,
				name: updatedName,
			};
		});

		if (value && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};

	const validateTime = (value) => {
		const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
		if (!regex.test(value)) return false;

		const [hours, minutes] = value.split(":").map(Number);
		const time = hours * 60 + minutes;

		const minTime = 7 * 60;
		const maxTime = 23 * 60;

		return time >= minTime && time <= maxTime;
	};

	const validateForm = () => {
		const newErrors = {};

		const weekDaysMapping = {
			"B.e.": { english: "monday", azerbaijani: "Bazar ertəsi" },
			"Ç.a.": { english: "tuesday", azerbaijani: "Çərşənbə axşamı" },
			"Ç.": { english: "wednesday", azerbaijani: "Çərşənbə" },
			"C.a.": { english: "thursday", azerbaijani: "Cümə axşamı" },
			"C.": { english: "friday", azerbaijani: "Cümə" },
			"Ş.": { english: "saturday", azerbaijani: "Şənbə" },
			"B.": { english: "sunday", azerbaijani: "Bazar" },
		};

		// if (!formData.descriptions.trim()) {
		// 	newErrors.descriptions = "Dərsin təsvirini daxil edin";
		// }

		if (!formData.group_id) {
			newErrors.group_id = "Qrupu seçin";
		}

		if (!formData.subject_id) {
			newErrors.subject_id = "Tədrisin istiqamətini seçin";
		}

		if (!formData.teacher_id) {
			newErrors.teacher_id = "Müəllimi seçin.";
		}

		if (formData.lesson_schedules.length === 0) {
			newErrors.lesson_schedules = "Ən azı bir dərs günü daxil edilməlidir.";
		} else {
			formData.lesson_schedules.forEach((schedule, index) => {
				const { week_day, start_time, end_time } = schedule;
				const dayName = Object.values(weekDaysMapping).find((day) => day.english === week_day)?.azerbaijani;

				if (!week_day) {
					newErrors[`lesson_schedules[${index}].week_day`] = "Dərs günü seçilməlidir.";
				}

				if (!start_time && !end_time) {
					newErrors[`lesson_schedules[${index}]`] = `${dayName} günü üçün başlama və bitmə vaxtını daxil edin.`;
				} else {
					if (!start_time) {
						newErrors[`lesson_schedules[${index}].start_time`] = `${dayName} günü üçün başlama vaxtı daxil edin.`;
					} else if (!validateTime(start_time)) {
						newErrors[`lesson_schedules[${index}].start_time`] = `${dayName} günü üçün başlama vaxtı düzgün formatda deyil (07:00 - 23:00).`;
					}

					if (!end_time) {
						newErrors[`lesson_schedules[${index}].end_time`] = `${dayName} günü üçün bitmə vaxtını daxil edin.`;
					} else if (!validateTime(end_time)) {
						newErrors[`lesson_schedules[${index}].end_time`] = `${dayName} günü üçün bitmə vaxtı düzgün formatda deyil (07:00 - 23:00).`;
					} else if (start_time >= end_time) {
						newErrors[`lesson_schedules[${index}].end_time`] = `${dayName} günü üçün bitmə vaxtı başlanğıc vaxtından sonra olmalıdır.`;
					}
				}
			});
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	// const validateTime = (value) => {
	// 	const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
	// 	return regex.test(value);
	// };

	const handleCheckboxChange = (e) => {
		const { value, checked } = e.target;

		setFormData((prevData) => {
			const newWeekDays = checked
				? [...prevData.week_day, value]
				: prevData.week_day.filter((day) => day !== value);

			const newSchedules = newWeekDays.map((day) => ({
				week_day: weekDaysMapping[day],
				time: prevData.start_time[day] || "",
				end_time: prevData.end_time[day] || "",
			}));

			return {
				...prevData,
				week_day: newWeekDays,
				schedules: newSchedules,
			};
		});
	};

	const [getData, setGetData] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	const [loadingTeachers, setLoadingTeachers] = useState(false);
	const [currentTeachersPage, setCurrentTeachersPage] = useState(1);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSubjects();
				setGetData(data);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchTeachers(null, 1000);
				setTeachers(data.items);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchGroups(null, 1000);
				setGroups(data.items);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const handleSubmit = async (shouldClose) => {
		if (validateForm()) {
			const headers = createHeaders();

			try {
				const response = await fetch(`${consts.API_URL}/admin/group-subject`, {
					method: "POST",
					headers: headers,
					body: JSON.stringify({
						group_id: formData.group_id,
						subject_id: formData.subject_id,
						teacher_id: formData.teacher_id,
						descriptions: formData.descriptions,
						lesson_schedules: formData.lesson_schedules,
					}),
				});

				const data = await response.json();

				if (response.ok) {
					if (!shouldClose) {
						setShowAlert(true);
						setAlertMessage("Dərs uğurla əlavə edildi!");
						setAlertType("success");
						// setTimeout(() => {
						// 	setShowAlert(false);
						// }, 2000);
						setFormData((prevData) => ({
							...prevData,
							subject_id: "",
							teacher_id: "",
							descriptions: "",
							lesson_schedules: [
								{
									week_day: "monday",
									start_time: "",
									end_time: "",
								},
							],
						}));
					} else {
						resetFormData();
						handleCloseModal();
						window.location.reload();
					}
				} else {
					if (
						data.errors &&
						data.errors.name &&
						data.errors.name.includes("The name has already been taken.")
					) {
						setErrors((prevErrors) => ({
							...prevErrors,
							name: "Bu qrup adı artıq istifadə olunur.",
						}));
					} else if (
						data.errors &&
						data.errors.lesson_schedules &&
						data.errors.lesson_schedules.includes("The teacher is busy at the specified time.")
					) {
						setErrors((prevErrors) => ({
							...prevErrors,
							lesson_schedules: "Müəllim müəyyən olunmuş vaxtda məşğuldur.",
						}));
					}
				}
			} catch (error) {
			}
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<div className="modal-title mb-3">
						<span>Yeni dərs məlumatları</span>
					</div>
				</Modal.Header>
				<Modal.Body className="modal-bodyy">
					<Form>
						<Row className="mb-4">

							<Col>
								<Form.Group controlId="formSelect" className="select-form">
									<Form.Label>Fənni seçin *</Form.Label>
									<CustomSelect
										options={getData?.map((subject) => ({
											value: subject?.id,
											label: subject?.translations?.[0]?.name || "Məlumat tapılmadı",
										})).filter(option => option.label)}
										value={
											formData.subject_id
												? { value: formData.subject_id, label: getData.find(subject => subject.id === formData.subject_id)?.translations[0].name || "" }
												: null
										}
										onChange={(selected) => handleChange({ name: 'subject_id', value: selected ? selected.value : "" })}
										placeholder="Seçin..."
										isInvalid={!!errors.subject_id}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.subject_id}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>

							<Col>
								<Form.Group controlId="formSelect" className="select-form">
									<Form.Label>Qrupu seçin *</Form.Label>
									<CustomSelect
										options={groups?.map(group => ({
											value: group.id,
											label: group.name,
										}))}
										// value={
										// 	formData.group_id
										// 		? { value: formData.group_id, label: groups.find(group => group.id === formData.group_id)?.name || "" }
										// 		: selectedGroup
										// 			? { value: selectedGroup.id, label: selectedGroup.name }
										// 			: null
										// }
										value={
											selectedGroup
												? { value: selectedGroup.id, label: selectedGroup.name }
												:
												formData.group_id
													? { value: formData.group_id, label: groups.find(group => group.id === formData.group_id)?.name || "" }
													: null
										}
										onChange={(selected) => handleChange({ name: 'group_id', value: selected ? selected.value : "" })}
										placeholder="Seçin..."
										isInvalid={!!errors.group_id}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.group_id}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>

						</Row>

						<Row className="mb-4">
							<Col>
								<Form.Group controlId="formDescriptions">
									<Form.Label>Dərs haqqında məlumat</Form.Label>
									<Form.Control
										type="text"
										name="descriptions"
										value={formData.descriptions}
										onChange={handleChange}
										placeholder="Məlumat daxil edin"
										isInvalid={!!errors.descriptions}
										className={errors.descriptions ? "input-error" : ""}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.descriptions}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="formTeacher" className="select-form">
									<Form.Label>Müəllimi seçin</Form.Label>
									<CustomSelect
										options={teachers?.map(teacher => ({ value: teacher.id, label: teacher.full_name }))}
										value={
											formData.teacher_id
												? { value: formData.teacher_id, label: teachers.find(teacher => teacher.id === formData.teacher_id)?.full_name || "" }
												: null
										}
										onChange={(selected) => handleChange({ name: 'teacher_id', value: selected ? selected.value : "" })}
										isInvalid={!!errors.teacher_id}
										placeholder="Seçin..."
									/>
									<Form.Control.Feedback type="invalid">
										{errors.teacher_id}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						<Row className="schedule-list">
							<Col md={12}>
								<div className="schedule-head d-flex align-items-center mb-3">
									<Form.Label className="mb-0">Dərs günləri əlavə edin</Form.Label>
									<button className="add-more"
										onClick={() => addMoreSchedule(formData.week_day?.[0] || "B.e.")}
										type="button"
									>+</button>
								</div>

								{formData.lesson_schedules.map((schedule, index) => (
									<div key={index} className="schedule-item mb-3">
										<Row>
											<Col md={4}>
												<Form.Control
													as="select"
													value={schedule.week_day}
													onChange={(e) => handleScheduleChange(e, "week_day", index)}
												>
													{Object.entries(weekDaysMapping).map(([label, day]) => (
														<option key={day} value={day}>
															{label}
														</option>
													))}
												</Form.Control>
											</Col>
											<Col md={3}>
												<InputMask
													mask="99:99"
													value={schedule.start_time}
													onChange={(e) => handleScheduleChange(e, "start_time", index)}
													placeholder="Başlama zamanı"
													className="form-control"
												/>
											</Col>
											<Col md={3}>
												<InputMask
													mask="99:99"
													value={schedule.end_time}
													onChange={(e) => handleScheduleChange(e, "end_time", index)}
													placeholder="Bitmə zamanı"
													className="form-control"
												/>
											</Col>
											<Col md={2} className="d-flex align-items-center justify-content-center">
												<button
													className="red-btn operation-btn d-flex align-items-center justify-content-center"
													onClick={() => removeSchedule(index)}>
													<svg
														width="22"
														height="22"
														viewBox="0 0 22 22"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M9.16667 9.16675C9.67293 9.16675 10.0833 9.57715 10.0833 10.0834V14.6667C10.0833 15.173 9.67293 15.5834 9.16667 15.5834C8.66041 15.5834 8.25 15.173 8.25 14.6667V10.0834C8.25 9.57715 8.66041 9.16675 9.16667 9.16675Z"
															fill="white"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M12.8334 9.16675C13.3397 9.16675 13.7501 9.57715 13.7501 10.0834V14.6667C13.7501 15.173 13.3397 15.5834 12.8334 15.5834C12.3272 15.5834 11.9167 15.173 11.9167 14.6667V10.0834C11.9167 9.57715 12.3272 9.16675 12.8334 9.16675Z"
															fill="white"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M9.16658 1.83325C7.6478 1.83325 6.41658 3.06447 6.41658 4.58325H3.66659H2.74992C2.24366 4.58325 1.83325 4.99366 1.83325 5.49992C1.83325 6.00618 2.24366 6.41658 2.74992 6.41658H3.66659V17.4166C3.66659 18.9354 4.8978 20.1666 6.41658 20.1666H15.5833C17.102 20.1666 18.3333 18.9354 18.3333 17.4166V6.41658H19.2499C19.7562 6.41658 20.1666 6.00618 20.1666 5.49992C20.1666 4.99366 19.7562 4.58325 19.2499 4.58325H18.3333H15.5833C15.5833 3.06447 14.352 1.83325 12.8333 1.83325H9.16658ZM13.7499 4.58325C13.7499 4.07699 13.3395 3.66659 12.8333 3.66659H9.16658C8.66032 3.66659 8.24992 4.07699 8.24992 4.58325H13.7499ZM6.41658 6.41658H5.49992V17.4166C5.49992 17.9228 5.91032 18.3333 6.41658 18.3333H15.5833C16.0895 18.3333 16.4999 17.9228 16.4999 17.4166V6.41658H15.5833H6.41658Z"
															fill="white"
														/>
													</svg>
												</button>

											</Col>
										</Row>
									</div>
								))}
							</Col>
							{/* <Col md={12} className="d-flex align-items-center justify-content-center">
							<p className="add-more blue-btn"
								onClick={() => addMoreSchedule(formData.week_day?.[0] || "B.e.")}>
								Dərs günü əlavə et
							</p>
						</Col> */}
						</Row>

						<Row>
							<Col className="mt-3 error-form">
								{Object.keys(errors)
									.filter((key) => key.startsWith("lesson_schedules"))
									.map((key) => {
										return (
											<div key={key} className="text-danger">
												{errors[key]}
											</div>
										);
									})}
							</Col>
						</Row>

					</Form>
					{showAlert && (
						<div className={`alert alert-${alertType}`} role="alert">
							{alertMessage}
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex w-100" style={{ gap: "5px" }}>
						<Button className="modal-btn btn-green" onClick={() => handleSubmit(false)} >
							Növbəti dərs
						</Button>
						<Button className="modal-btn btn-green" onClick={() => handleSubmit(true)}>
							Yadda saxla
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default AddLesson;
