import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
// import { , fetchAboutMe } from "../../functions/apiService";
import { fetchTeacherGroups, fetchTeachersSubjects, createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useNavigate } from "react-router-dom";
import * as consts from "../../consts/Consts";


function OnlineLessonModal({ show, handleClose }) {
	const [formData, setFormData] = useState({
		group_id: "",
		group_subject_id: "",
		online_lesson_link: "",
	});

	const [errors, setErrors] = useState({});
	const [groups, setGroups] = useState([]);
	const [lessons, setLessons] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedGroup, setSelectedGroup] = useState(null)
	const [selectedGroupSubject, setSelectedGroupSubject] = useState(null)
	const navigate = useNavigate()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchTeacherGroups(navigate);

				const transformedGroups = response.map(item => ({
					id: item.id,
					name: item.name,
				}));

				setGroups(transformedGroups);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetchTeachersSubjects(navigate, selectedGroup);

				const extractedLesson = Array.isArray(response.data)
					? response.data.map(item => ({
						groupSubjectID: item.id,
						subject: {
							id: item.subject.id,
							translations: item.subject.translations || [],
						},
					}))
					: [];
				setLessons(extractedLesson);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate, selectedGroup]);

	const resetFormData = () => {
		setFormData({
			group_id: "",
			group_subject_id: "",
			online_lesson_link: "",
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		if (name === "group_id") {
			setSelectedGroup(value);
		}

		if (name === "group_subject_id") {
			setSelectedGroupSubject(value);
		}

		if (value.trim() && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};

	const validateForm = () => {
		const newErrors = {};

		if (!formData.group_id) {
			newErrors.group_id = "Qrup seçin";
		}

		if (!formData.group_subject_id) {
			newErrors.group_subject_id = "Dərs seçin";
		}

		if (!formData.online_lesson_link) {
			newErrors.online_lesson_link = "Dərsin linkini daxil edin";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		const headers = createHeaders()
		e.preventDefault();
		if (validateForm()) {
			try {
				const response = await fetch(`${consts.API_URL}/teacher/group-subject/${selectedGroupSubject}/set/online-link`, {
					method: "PUT",
					headers: headers,
					body: JSON.stringify({
						online_lesson_link: formData.online_lesson_link,
					}),
				});
				const data = await response.json();
				if (response.ok) {
					resetFormData();
					handleClose();
				} else {
					if (data.errors && data.errors.online_lesson_link && data.errors.online_lesson_link.includes("The online lesson link field must be a valid URL.")) {
						setErrors((prevErrors) => ({
							...prevErrors,
							online_lesson_link: "URL düzgün deyil.",
						}));
					} else {
						// console.error("Error adding Student:", data.message, data.errors);
					}
				}
			} catch (error) {
				//   console.error("Error submitting form:", error);
			}
		}
	};


	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	console.log(lessons)

	return (
		<Modal show={show} onHide={handleCloseModal} className="note-modal" backdrop="static">
			<Modal.Header closeButton onHide={handleCloseModal}>
				<Modal.Title className="online-title">Onlayn Dərs Yarat</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit}>
				<Modal.Body>
					<Form.Group controlId="subject" className="select-form mb-4">
						<Form.Label>Qrup</Form.Label>
						<div className="position-relative">
							<Form.Control
								as="select"
								name="group_id"
								value={formData.group_id}
								onChange={handleChange}
								isInvalid={!!errors.group_id}
								className={errors.group_id ? "input-error" : ""}
							>
								<option value="">Qrup seçin</option>
								{groups?.map((group) => (
									<option key={group.id} value={group.id}>
										{group.name}
									</option>
								))}
							</Form.Control>
							<svg
								width="14"
								height="8"
								viewBox="0 0 14 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 1L7 7L13 1"
									stroke="#6F767E"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<Form.Control.Feedback type="invalid">
							{errors.group_id}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="subject" className="select-form mb-4">
						<Form.Label>Dərs</Form.Label>
						<div className="position-relative">
							<Form.Control
								as="select"
								name="group_subject_id"
								value={formData.group_subject_id}
								onChange={handleChange}
								isInvalid={!!errors.group_subject_id}
								className={errors.group_subject_id ? "input-error" : ""}
								disabled={!selectedGroup}
							>
								<option value="">Dərs seçin</option>
								{lessons.map((lesson) => (
									<option key={lesson.groupSubjectID} value={lesson.groupSubjectID}>
										{lesson.subject.translations[0]?.name || 'No Name'}
									</option>
								))}
							</Form.Control>
							<svg
								width="14"
								height="8"
								viewBox="0 0 14 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 1L7 7L13 1"
									stroke="#6F767E"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<Form.Control.Feedback type="invalid">
							{errors.group_subject_id}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="online_lesson_link">
						<Form.Label>Linki əlavə edin</Form.Label>
						<Form.Control
							as="textarea"
							rows={7}
							name="online_lesson_link"
							onChange={handleChange}
							value={formData.online_lesson_link}
							isInvalid={!!errors.online_lesson_link}
							className={errors.online_lesson_link ? "input-error" : ""}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.online_lesson_link}
						</Form.Control.Feedback>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button type="submit" className="note-btn modal-btn btn-green">
						Online dərsi yarat
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

export default OnlineLessonModal;
