import TeachingDirectionsTable from "../components/admin/TeachingDirectionsTable";

const TeachingDirections = () => {
	return (
		<>
			<section>
				<h3 className="title mb-3">Fənnlər</h3>
				<TeachingDirectionsTable />
			</section>
		</>
	);
};

export default TeachingDirections;
