import { Link } from "react-router-dom";
import GradeTable from "../components/teachers/GradeTable";
import NotesTable from "../components/teachers/NotesTable";

const WatchingGroup = ({ role }) => {
	return (
		<>
			<section className="grade">
				<div className="mb-5">
					<GradeTable />
				</div>

				<div>
					<NotesTable role={role} />
				</div>
			</section>
		</>
	);
};

export default WatchingGroup;
