import React, { useState } from "react";

function GradeTableItemStudent({ data, role }) {

	return (
		<>
			{data && data?.grades?.length > 0 ? (
				data.grades.map((gradeItem, index) => (
					<tr key={index}>
						<td>
							<div className="left column-content">{gradeItem.date}</div>
						</td>
						<td>
							<div
								className="column-content"

							>
								<span className="column-content-grade"
									style={{
										color: gradeItem.grade ? "#141518" : "#FFFFFF",
										backgroundColor: gradeItem.grade
											? "#1AE5BE"
											: gradeItem.attendance === 1
												? "#246CF9"
												: gradeItem.attendance === 0
													? "#FC5A5A"
													: ""
									}}
								>
									{gradeItem.grade ? gradeItem.grade : !gradeItem.grade && gradeItem.attendance === 1 ? "+" : !gradeItem.grade && gradeItem.attendance === 0 ? "Q" : ""}
								</span>
							</div>
						</td>
						<td>
							<div className="column-content">
								{gradeItem && gradeItem.status === 'daily' ? 'Gündəlik' :
									gradeItem && gradeItem.status === 'quiz' ? 'Quiz' :
										gradeItem && gradeItem.status === 'project' ? 'Layihə' :
											gradeItem && gradeItem.status === 'exam' ? 'İmtahan' :
												"Məlumat tapılmadı"
								}
							</div>

						</td>
					</tr>
				))
			)
				:
				(
					<tr>
						<td colSpan={4} className="empty-table">Seçdiyiniz günlər üzrə qiymətlər yoxdur</td>
					</tr>
				)}

		</>
	);
}

export default GradeTableItemStudent;
