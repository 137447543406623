import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useNavigate } from "react-router-dom";
import { fetchStudentGroups } from "../../functions/apiService";

function OnlineLessonModal({ show, handleClose }) {
	const navigate = useNavigate()
	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchStudentGroups(navigate);
				setGroups(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);


	const handleCloseModal = () => {
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleCloseModal} className="note-modal" backdrop="static">
			<Modal.Header closeButton onHide={handleCloseModal}>
				<Modal.Title className="online-title">Onlayn Dərsə Qoşul</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					{groups?.some(group =>
						group.lessons?.some(lesson => lesson.online_lesson_link)
					) ? (
						groups.map(group =>
							group.lessons?.map(lesson =>
								lesson.online_lesson_link && (
									<Col xs={12} key={lesson.id} className="mb-3">
										<div className="online-item d-flex align-items-center justify-content-between">
											<div className="online-body">
												<p className="online-subtitle">{group.name}</p>
												<p className="online-subtitle">
													{lesson.subject.translations?.[0]?.name || "Название недоступно"}
												</p>
											</div>
											<div className="online-body">
												<Link
													to={lesson.online_lesson_link}
													target="_blank"
													rel="noopener noreferrer"
													className="online-btn"
												>
													Dərsə qoşul
												</Link>
											</div>
										</div>
									</Col>
								)
							)
						)
					) : (
						<Col xs={12}>
							<p className="online-text">Mövcüd dərs yoxdur.</p>
						</Col>
					)}
				</Row>

			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	);
}

export default OnlineLessonModal;
