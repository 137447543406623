import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { createHeaders, fetchSubjects, fetchTeachersSubjects } from "../functions/apiService";
import TopStudents from "../components/admin/TopStudents";
import ContactCard from "../components/admin/ContactCard";
import * as consts from "../consts/Consts"
import CustomSelect from '../components/CustomSelect';

function TopStudent() {
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [subjects, setSubjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedSubject, setSelectedSubject] = useState("");
	const [topStudents, setTopStudents] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchTeachersSubjects();
				setSubjects(data);
				if (data.length > 0) {
					setSelectedSubject({ value: data[0].id, label: data[0].name });
				}
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);


	const getTopStudents = async (subjectId) => {
		const headers = createHeaders();
		const url = subjectId
			? `${consts.API_URL}/site/statics/top-students?subject_id=${subjectId}`
			: `${consts.API_URL}/site/statics/top-students`;

		try {
			const response = await fetch(url, {
				method: "GET",
				headers: headers,
			});

			if (!response.ok) {
				throw new Error("Error fetching top students");
			}

			const data = await response.json();
			setTopStudents(data.data);
		} catch (error) {
			// console.error("Error fetching top students:", error);
		}
	};

	useEffect(() => {
		getTopStudents(selectedSubject?.value);
	}, [selectedSubject]);

	return (
		<Col xs={12} md={4} lg={4}>
			<div className="user__right">
				<div className="top">
					<Row className="top__head">
						<Col xs={4}>
							<h3 className="top__label col">Top-5 tələbə</h3>
						</Col>
						<Col xs={8}>
							<div className="top__filter">
								{/* <CustomSelect
									options={subjects?.map(subject => ({
										value: subject.id,
										label: subject.name,
									}))}
									value={selectedSubject}
									onChange={setSelectedSubject}
									placeholder="Fənn"
									isInvalid={false}
								/> */}
							</div>
						</Col>
					</Row>
					<div className="top__list">
						{topStudents.length > 0 ? (
							topStudents.map((studentData) => (
								<TopStudents
									key={studentData.id}
									data={studentData}
								/>
							))
						) : (
							<p>Bu ixtisasda statistika yoxdur!</p>
						)}
					</div>
				</div>
			</div>

			<ContactCard />
		</Col>
	);
}

export default TopStudent;

