import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChartStudent = () => {
	const [state, setState] = React.useState({
		series: [60, 22, 12, 6],
		options: {
			chart: {
				width: 300,
				height: 300,  // Добавляем фиксированную высоту для графика
				type: 'pie',
				background: '#FFFFFF',
			},
			labels: ['Əla', 'Yaxşı', 'Kafi', 'Qayıb'],
			colors: ['#1AE5BE', '#246CF9', '#F59E0B', '#FC5A5A'], // Задаем цвета сегментов
			dataLabels: {
				enabled: false, // Отключаем отображение значений внутри сегментов
			},
			title: {
				text: 'Nəticə bölgüsü', // Название графика
				margin: 40,
				offsetY: 10,
				style: {
					fontSize: '20px',
					fontWeight: '600',
					color: '#000000',
				},
			},
			legend: {
				position: 'right', // Легенда расположена справа
				verticalAlign: 'middle', // Выравниваем по вертикали в центре
				horizontalAlign: 'center',
				fontSize: '14px',
				formatter: (seriesName, opts) => {
					// Форматируем легенду с отображением значения
					return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}%`;
				},
				labels: {
					colors: ['#404040'], // Цвет текста в легенде
				},
				itemMargin: {
					vertical: 10, // Расстояние между элементами легенды
				},
			},
			responsive: [
				{
					breakpoint: 1410,
					options: {
						chart: {
							width: 330, // Увеличиваем ширину для экранов 1410px
							height: 330,  // Устанавливаем высоту для экранов 1410px
						},
						legend: {
							position: 'right',
							horizontalAlign: 'center',
						},
					},
				},
				{
					breakpoint: 1300,
					options: {
						chart: {
							width: 295,
							height: 330,
						},
						legend: {
							position: 'right',
							horizontalAlign: 'center',
						},
					},
				},
				{
					breakpoint: 1200,
					options: {
						chart: {
							width: 250,
							height: 330,
						},
						legend: {
							position: 'right',
							horizontalAlign: 'center',
						},
					},
				},
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
							height: 200,  // Устанавливаем высоту для маленьких экранов
						},
						legend: {
							position: 'bottom',
						},
					},
				},
			],
		},
	});

	return (
		<div
			style={{
				backgroundColor: '#FFFFFF',
				padding: '20px 0 20px 20px',
				borderRadius: '20px',
				// boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
				maxWidth: '100%', // Ограничиваем максимальную ширину контейнера
			}}
		>
			<ReactApexChart options={state.options} series={state.series} type="pie" width="100%" height={300} />
		</div>
	);
};

export default PieChartStudent;
