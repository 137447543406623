import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContactModal from "./ContactModal";
import { Link, useLocation } from "react-router-dom";
import { fetchContact } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

const ContactCard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(false);

  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchContact(navigate);
        setGetData(data);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <ContactModal
        show={showModal}
        handleClose={handleShowModal}
        //   links={links}
        //   setLinks={setLinks}
      />
      <div className="contact-card">
        <div className="cc_top">
          <h4 className="cc_title">Əlaqə</h4>
          {pathname === "/admin" && (
            <button className="cc_edit-btn" onClick={() => setShowModal(true)}>
              Redaktə et
            </button>
          )}
        </div>
        <div className="cc_bottom">
          <div className="links">
            {getData.linkedin && (
              <Link to={getData.linkedin}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9V16"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 12V16M10 12C10 10.3431 11.3431 9 13 9C14.6569 9 16 10.3431 16 12V16M10 12V9"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.00801 6L5.99902 6"
                    stroke="#101110"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            )}

            {getData.facebook && (
              <Link to={getData.facebook}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 7.75C16.4142 7.75 16.75 7.41421 16.75 7C16.75 6.58579 16.4142 6.25 16 6.25V7.75ZM15 7V7.75V7ZM10.25 20.5C10.25 20.9142 10.5858 21.25 11 21.25C11.4142 21.25 11.75 20.9142 11.75 20.5H10.25ZM9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75V12.25ZM14 13.75C14.4142 13.75 14.75 13.4142 14.75 13C14.75 12.5858 14.4142 12.25 14 12.25V13.75ZM16 6.25H15V7.75H16V6.25ZM10.25 11V20.5H11.75V11H10.25ZM15 6.25C14.0784 6.25 13.3117 6.24841 12.7055 6.32991C12.0777 6.41432 11.5109 6.59999 11.0555 7.05546L12.1161 8.11612C12.2464 7.9858 12.4439 7.87858 12.9054 7.81654C13.3884 7.75159 14.036 7.75 15 7.75V6.25ZM11.75 11C11.75 10.036 11.7516 9.38843 11.8165 8.90539C11.8786 8.44393 11.9858 8.24643 12.1161 8.11612L11.0555 7.05546C10.6 7.51093 10.4143 8.07773 10.3299 8.70552C10.2484 9.31174 10.25 10.0784 10.25 11H11.75ZM9 13.75H14V12.25H9V13.75Z"
                    fill="#101110"
                  />
                </svg>
              </Link>
            )}

{getData.whatsapp && (
  <Link to={`https://web.whatsapp.com/send?phone=${getData.whatsapp}`}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.3789 2.27907 14.6926 2.78382 15.8877C3.06278 16.5481 3.20226 16.8784 3.21953 17.128C3.2368 17.3776 3.16334 17.6521 3.01642 18.2012L2 22L5.79877 20.9836C6.34788 20.8367 6.62244 20.7632 6.87202 20.7805C7.12161 20.7977 7.45185 20.9372 8.11235 21.2162C9.30745 21.7209 10.6211 22 12 22Z"
        stroke="#101110"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.58815 12.3773L9.45909 11.2956C9.82616 10.8397 10.2799 10.4153 10.3155 9.80826C10.3244 9.65494 10.2166 8.96657 10.0008 7.58986C9.91601 7.04881 9.41086 7 8.97332 7C8.40314 7 8.11805 7 7.83495 7.12931C7.47714 7.29275 7.10979 7.75231 7.02917 8.13733C6.96539 8.44196 7.01279 8.65187 7.10759 9.07169C7.51023 10.8548 8.45481 12.6158 9.91948 14.0805C11.3842 15.5452 13.1452 16.4898 14.9283 16.8924C15.3481 16.9872 15.558 17.0346 15.8627 16.9708C16.2477 16.8902 16.7072 16.5229 16.8707 16.165C17 15.8819 17 15.5969 17 15.0267C17 14.5891 16.9512 14.084 16.4101 13.9992C15.0334 13.7834 14.3451 13.6756 14.1917 13.6845C13.5847 13.7201 13.1603 14.1738 12.7044 14.5409L11.6227 15.4118"
        stroke="#101110"
        strokeWidth="1.5"
      />
    </svg>
  </Link>
)}


            {getData.instagram && (
              <Link to={getData.instagram}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M17.5078 6.5L17.4988 6.5"
                    stroke="#101110"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            )}

            {getData.web_site && (
              <Link to={getData.web_site}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M19 4.69899C18.0653 4.76636 16.8681 5.12824 16.0379 6.20277C14.5385 8.14361 13.039 8.30556 12.0394 7.65861C10.5399 6.6882 11.8 5.11636 10.0401 4.26215C8.89313 3.70542 8.73321 2.19045 9.37158 1"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 10C1.7625 10.6621 2.83046 11.2682 4.08874 11.2682C6.68843 11.2682 7.20837 11.7649 7.20837 13.7518C7.20837 15.7387 7.20837 15.7387 7.72831 17.2288C8.06651 18.1981 8.18472 19.1674 7.5106 20"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 12.4523C20.1129 11.9411 19 11.7308 17.8734 12.5405C15.7177 14.0898 14.2314 12.806 13.5619 14.0889C12.5765 15.9775 16.0957 16.5711 13 21"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            )}

            {getData.youtube && (
              <Link to={getData.youtube}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z"
                    stroke="#101110"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
