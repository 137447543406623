import React, { useState, useEffect } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const SemicircleProgressBarStudent = () => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prevProgress) => {
				if (prevProgress < 100) {
					return Math.min(prevProgress + 20, 76);
				}
				clearInterval(interval);
				return prevProgress;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#fff',
				height: '100%',
				flexDirection: 'column',
				textAlign: 'center',
				borderRadius: '20px',
			}}
		>
			{/* Заголовок */}
			<h3 style={{ marginBottom: '20px', fontSize: '20px', fontWeight: '600' }}>Maksimal performans</h3>

			{/* Прогресс-бар */}
			<div
				style={{
					width: 200,
					height: 200,
					transform: 'rotate(-90deg)',
					backgroundColor: 'white',
					position: 'relative',
				}}
			>
				<CircularProgressbarWithChildren
					value={progress}
					strokeWidth={10}
					circleRatio={0.5}  // Полукруглый прогресс-бар
					styles={{
						path: {
							stroke: '#1AE5BE',
						},
						trail: {
							stroke: '#F1F1F5',
						},
						text: {
							fontSize: '20px',
							fontWeight: 'bold',
							fill: '#333',
						},
					}}
				>
					{/* Центрируем текст */}
					{/* <div
						style={{
							fontSize: '20px',
							fontWeight: 'bold',
							color: '#333',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							transform: 'rotate(90deg)',
						}}
					>
						{progress}%
					</div> */}

					{/* Текст на минимальном значении (0%) */}
					<div
						style={{
							position: 'absolute',
							top: '47%',
							left: '-12%',
							width: '100%',
							textAlign: 'left',
							fontSize: '12px',
							color: '#101110',
							transform: 'translateX(10px)',
							transform: 'rotate(90deg)',
						}}
					>
						0
					</div>

					{/* Текст на максимальном значении (100%) */}
					<div
						style={{
							position: 'absolute',
							top: '47%',
							right: '12%',
							width: '100%',
							textAlign: 'right',
							fontSize: '12px',
							color: '#101110',
							transform: 'translateX(-10px)',
							transform: 'rotate(90deg)',
						}}
					>
						100
					</div>
				</CircularProgressbarWithChildren>

				{/* Дополнительная надпись */}
				<div
					style={{
						position: 'absolute',
						bottom: '38%', // Располагаем ниже процента
						right: '32%',
						width: '48px',
						height: '48px',
						fontSize: '20px',
						borderRadius: '50%',
						background: '#F1F1F5',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						transform: 'rotate(90deg)', // Поворачиваем текст для соответствия прогресс-бару
					}}
				>
					<span>
						🚀
					</span>
				</div>
				<div
					style={{
						position: 'absolute',
						bottom: '41%',
						right: '70%',
						fontSize: '20px',
						fontWeight: 'bold',
						color: '#333',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						transform: 'rotate(90deg)',
					}}
				>
					{progress}%
				</div>
				<div
					style={{
						position: 'absolute',
						bottom: '43%', // Располагаем ниже процента
						right: '45%',
						width: '100%',
						fontSize: '14px',
						color: '#101110',
						transform: 'rotate(90deg)', // Поворачиваем текст для соответствия прогресс-бару
					}}
				>
					Həftəlik ortalamaya əsasən
				</div>

			</div>
		</div>
	);
};

export default SemicircleProgressBarStudent;
