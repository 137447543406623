import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import * as consts from "../../consts/Consts";
import { createHeaders } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function BlockModal({
	show,
	handleClose,
	data,
	apiPath,
	isBlocked, // No need to toggle isBlocked on button click
}) {
	const navigate = useNavigate();

	const handleBlock = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(
				`${consts.API_URL}/admin/${apiPath}/set/block`,
				{
					method: "POST",
					headers,
					body: JSON.stringify({
						id: data.id,
						is_blocked: isBlocked ? 0 : 1, // Toggle based on the initial state
					}),
				}
			);
			if (response.ok) {
				handleClose();
				window.location.reload(); // Reload the page after action is completed
			} else {
				// Handle non-OK responses here if necessary
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};

	const getTitle = () => {
		if (data && data.role === "teacher") {
			return `Müəllimi ${!isBlocked ? "bloklamaq" : "blokdan çıxarmaq"
				} istəyirsinizmi?`;
		}
		if (data && data.role === "student") {
			if (data.status === "graduated") {
				return `Məzunu ${!isBlocked ? "bloklamaq" : "blokdan çıxarmaq"
					} istəyirsinizmi?`;
			} else if (data.status === "pending") {
				return `Layihə gözləyəni ${!isBlocked ? "bloklamaq" : "blokdan çıxarmaq"
					} istəyirsinizmi?`;
			}

			return `Tələbəni ${!isBlocked ? "bloklamaq" : "blokdan çıxarmaq"
				} istəyirsinizmi?`;
		}
		return "Qrupu bloklamaq istəyirsinizmi?";
	};

	const getContent = () => {
		if (data && data.role === "teacher") {
			return `${data.full_name} adlı ${!isBlocked
					? "müəllimin sistemə girişi bloklanacaq"
					: "müəllim blokdan çıxarılacaq"
				}!`;
		}
		if (data && data.role === "student") {
			if (data.status === "graduated") {
				return `${data.full_name} adlı ${!isBlocked
						? "məzunun sistemə girişi bloklanacaq"
						: "məzun blokdan çıxarılacaq"
					}!`;
			} else if (data.status === "pending") {
				return `${data.full_name} adlı ${!isBlocked
						? "layihə gözləyənin sistemə girişi bloklanacaq"
						: "layihə gözləyən blokdan çıxarılacaq"
					}!`;
			}

			return `${data.full_name} adlı ${!isBlocked
					? "tələbənin sistemə girişi bloklanacaq"
					: "tələbə blokdan çıxarılacaq"
				}!`;
		}
		// For groups or other entities
		return `${data.name} ${!isBlocked ? "sistemə girişi bloklanacaq" : "blokdan çıxarılacaq"
			}!`;
	};

	return (
		<Modal show={show} onHide={handleClose} className="student-modal" backdrop="static">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="alert-modal">
					<div className="alert-image">
						<svg
							width="72"
							height="72"
							viewBox="0 0 72 72"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle
								cx="35.9997"
								cy="35.9999"
								r="34.1667"
								strokeWidth="3"
								stroke="#FC5A5A"
							/>
							<path
								d="M35.972 46.25H36.0027"
								stroke="#FC5A5A"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M36 36L36 22.3333"
								stroke="#FC5A5A"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="alert-message">
						<h2 className="alert-title">{getTitle()}</h2>
						<p className="alert-content">{getContent()}</p>
						<div className="buttons mt-3">
							<Button type="button" className="no-button" onClick={handleClose}>
								Xeyr
							</Button>
							<Button
								type="button"
								className="yes-button"
								onClick={handleBlock}
							>
								Bəli
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer></Modal.Footer>
		</Modal>
	);
}

export default BlockModal;
